/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { TextField, Input } from '@mui/material';
import NumberFormat from 'react-number-format';
import { styled } from '@mui/material/styles';
import moment from 'moment';

const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "rgb(50,50,50)",
        color: "rgb(50,50,50)"
    },
    ".MuiInputBase-input.Mui-disabled:hover": {
        cursor: "not-allowed"
    }
}));

const NumberFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            isNumericString
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
        />
    );
});
class CustomTextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? (props.datetime && props.value.length > 0 ? moment(props.value[0]).format('DD/MM/YYYY LT - ') + moment(props.value[1]).format('LT') : String(props.value).trim()) : '',
            error: false,
            shrink: false,
        };
        this.maxValue = props.maxValue;
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.value;
        if (this.props.datetime && newValue && newValue.length > 0 && newProps.hasOwnProperty('value') && newValue !== this.state.value) {
            this.setState({
                value: moment(newValue[0]).format('DD/MM/YYYY LT - ') + moment(newValue[1]).format('LT')
            });
        } else if (newProps.hasOwnProperty('value') && newValue !== this.state.value && newValue) {
            this.setState({
                value: String(newValue).trim(),
                shrink: true,
            });
        }
    }
    handleChange = (val) => {
        if (this.maxValue && (this.props.keyboardType === 'number' || this.props.keyboardType === 'numeric')) {
            if (val <= this.maxValue) {
                this.setState({ error: false, value: val });
                this.props.onChange !== undefined && this.props.onChange(val);
            }
        } else {
            this.setState({ error: false, value: val });
            this.props.onChange !== undefined && this.props.onChange(val);
        }
    };

    checkIsEmpty() {
        if (this.state.value) {
            this.setState({ error: false });
            return true;
        } else {
            if (this.props.isRequired) {
                this.setState({ error: true });
                return false;
            }

            return true;
        }
    }
    render() {
        const { label, keyboardType, isRequired, editable } = this.props;
        return (
            <CustomDisableInput
                style={{ width: '100%', appearance: 'none', color: "#000" }}
                error={this.state.error}
                required={isRequired}
                type={keyboardType !== 'numeric' ? keyboardType : undefined}
                disabled={!editable}
                label={label}
                autoCapitalize="true"
                onFocus={() => this.setState({ shrink: true })}
                onBlur={() => this.setState({ shrink: false })}
                InputLabelProps={{ shrink: this.state.shrink || this.state.value ? true : false }}
                onChange={(val) => this.handleChange(val.target.value)}
                InputProps={{
                    inputComponent: keyboardType === 'numeric' ? NumberFormatCustom : Input,
                }}
                value={this.state.value}
                margin="normal"
            />
        );
    }
}
export default CustomTextInput;
