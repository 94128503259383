class EkipModel {
    constructor() {
        this.Ekip = {
            _id: '',
            ekipName: '', //true
            employees: '', //true
            selectedArea: '', //true
            selectedCoordinates: '' //true
        };
    }
}
export default EkipModel;
