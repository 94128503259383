const colors = {
  whiteCustom: '#FEFEFE',
  darkBlue: '#29487D',
  customOrange: '#ff6e41',
  lightBlue: '#3C5898',
  iceBlue: '#9CB2CE',
  grayCustom: '#D4D8E8',
  customBlack: '#242424',
  carrot: '#e67e22',
  emerald: '#2ecc71',
  peterRiver: '#3498db',
  wisteria: '#8e44ad',
  alizarin: '#e74c3c',
  turquoise: '#1abc9c',
  red: '#d12424',
  midnightBlue: '#2c3e50',
  denetmen: '#006fba',
};

export default colors;
