import React, { useState, useEffect } from 'react';
import metrics from '../../metrics';
import api from '../../functions/api';
import Commons from '../../functions/Commons';
import moment from 'moment';

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, MenuItem, Checkbox } from '@mui/material';
import weekInput from '../../inputfields/weekInputs.json';
import hoursInputs from '../../inputfields/hoursInputs.json';

import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';

var countObj;
const hours = hoursInputs;

const App = () => {
    const [ekipList, setEkipList] = useState([]);
    const [copyWeekInput, setCopyWeekInput] = useState(1);

    // Scheduler referance object
    const scheduleObj = React.useRef(null)

    // Scheduler settings
    const [workHours, setWorkHours] = useState({ highlight: true, start: "09:00", end: "18:00" });
    const [workDays, setWorkDays] = useState("[1 , 2 , 3 , 4, 5]");
    const [timeScale, setTimeScale] = useState({ enable: true, interval: '60', slotCount: 1, majorSlotTemplate: null, minorSlotTemplate: null });

    // Settings section inputs
    const [ekipInput, setEkipInput] = useState("")
    const [workDaysInput, setWorkDaysInput] = useState(["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma"])
    const [workHoursInput, setWorkHoursInput] = useState({ start: "09:00", end: "18:00" })
    const [timeScaleInput, setTimeScaleInput] = useState({ interval: '60' })

    useEffect(() => {
        Promise.all([
            api.getEkip(),
            api.getSchedulerSettings()
        ]).then(([ekips, settings]) => {
            setEkipList(ekips.Items);
            setEkipInput(ekips.Items[0]?._id);

            if (settings) {
                setWorkHours({ ...settings.workHours, highlight: true });
                setTimeScale((t) => ({ ...t, ...settings.timeScale }));
                setWorkDays(settings.workDaysIndex);

                setWorkHoursInput(settings.workHours);
                setTimeScaleInput(settings.timeScale);
                setWorkDaysInput(settings.workDaysName);
            }
        }).catch(err => console.log(err));
    }, [])

    const dataManger = new DataManager({
        url: `${metrics.URL}/getScheduleData/?ekipId=${ekipInput}`,
        crudUrl: metrics.URL + '/updateScheduleData',
        headers: [{ authorization: Commons.getAuthorization() }],
        adaptor: new UrlAdaptor()
    });

    function editorTemplate(props) {
        return (props !== undefined ?
            <div style={{ width: '100%', padding: '20px 10px' }}>

                <div style={{ fontSize: '14px', marginBottom: '15px' }}>
                    {moment(props.StartTime).format('LL') + " "}
                    <span style={{ fontWeight: 'bold' }}>{moment(props.StartTime).format('LT') + " - " + moment(props.EndTime).format('LT')}</span>
                </div>
                {props.Subject ?
                    <>
                        <label style={{ display: 'inline-block', minWidth: '40px' }}>Randevu adeti</label> :
                        <input id="Count" className="e-field e-input" type="number" name="Subject" min={1} defaultValue={props.Subject} style={{ width: '45px', marginLeft: '10px' }} />
                    </> :
                    <div>
                        <input id="Count" className="e-field e-input" type="number" name="Subject" placeholder='Randevu adeti' style={{ width: '188px' }} />
                    </div>
                }
            </div> : <div></div>
        );
    };

    function headerTemplate(props) {
        return (
            <div style={{ backgroundColor: "#ffffff", padding: "12px 18px 0 18px", fontWeight: 500, fontSize: "18px", textAlign: 'center' }}>
                {(props.elementType === 'cell') ?
                    <>
                        {moment(props.StartTime).format('LL') + " " + moment(props.StartTime).format('LT') + " - " + moment(props.EndTime).format('LT')}
                    </> :
                    'Detay'}
            </div>
        );
    }

    function contentTemplate(props) {
        return (<div>
            {props.elementType === 'cell' ?
                <div className="e-cell-content">
                    <div className="content-area">
                        <input ref={(ref) => { countObj = ref }} min={1} id="Count" className="e-field e-input" type="number" name="Subject" placeholder="Randevu adeti giriniz" style={{ width: '100%' }} />
                    </div>
                </div>
                :
                <div style={{ height: "30px", display: "flex", flexDirection: 'column', justifyContent: 'center', padding: '0 15px', marginBottom: '-30px' }}>
                    <div style={{ fontSize: '14px', letterSpacing: '0.33px', height: '24px', padding: '5px' }}>
                        <label style={{ display: 'inline-block', minWidth: '40px' }}>Randevu adeti</label>:
                        <span style={{ letterSpacing: '0.33px', lineHeight: '14px', paddingLeft: '8px', fontWeight: 'bold' }}>{props.Subject}</span>
                    </div>
                </div>}
        </div>);
    }

    function footerTemplate(props) {
        return (
            <div className="quick-info-footer">
                {props.elementType === "cell" ?
                    <div className="cell-footer">
                        {/* <Button id="more-details" cssClass='e-flat' onClick={(e) => buttonClickActions(e)}>Detaylar</Button> */}
                        <Button id="add" cssClass='e-flat' onClick={(e) => buttonClickActions(e)}>Ekle</Button>
                    </div>
                    :
                    <div className="event-footer" >
                        <Button id="more-details" cssClass='e-flat' onClick={(e) => buttonClickActions(e)}>Düzenle</Button>
                        <Button id="delete" cssClass='e-flat' onClick={(e) => buttonClickActions(e)}>Sil</Button>
                    </div>
                }
            </div>
        )
    }

    function buttonClickActions(e) {
        const quickPopup = scheduleObj.current.element.querySelector('.e-quick-popup-wrapper');
        const getSlotData = () => {
            const cellDetails = scheduleObj.current.getCellDetails(scheduleObj.current.getSelectedElements());
            const addObj = {};
            addObj.Id = scheduleObj.current.getEventMaxID();
            addObj.Subject = countObj.value;
            addObj.StartTime = new Date(+cellDetails.startTime);
            addObj.EndTime = new Date(+cellDetails.endTime);
            addObj.ekipId = ekipInput;
            return addObj;
        };
        if (e.target.id === 'add') {
            const addObj = getSlotData();
            scheduleObj.current.isSlotAvailable(addObj.StartTime, addObj.EndTime) && // Alan boş değilse eklemeye izin vermiyor
                scheduleObj.current.addEvent(addObj);
        }
        else if (e.target.id === 'delete') {
            const eventDetails = scheduleObj.current.activeEventData.event;
            let currentAction = 'Delete';
            if (eventDetails.RecurrenceRule) {
                currentAction = 'DeleteOccurrence';
            }
            scheduleObj.current.deleteEvent(eventDetails, currentAction);
        }
        else {
            const isCellPopup = quickPopup.firstElementChild.classList.contains('e-cell-popup');
            const eventDetails = isCellPopup ? getSlotData() :
                scheduleObj.current.activeEventData.event;
            let currentAction = isCellPopup ? 'Add' : 'Save';
            if (eventDetails.RecurrenceRule) {
                currentAction = 'EditOccurrence';
            }
            scheduleObj.current.openEditor(eventDetails, currentAction, true);
        }
        scheduleObj.current.closeQuickInfoPopup();
    }

    function saveSettingsHandler() {
        if (timeScaleInput.interval >= 5) {

            var dayIndex = []
            workDaysInput.forEach(day => {
                weekInput.forEach((input) => {
                    input.name === day &&
                        dayIndex.push(input.value)
                });
            })
            var data = {
                workHours: workHoursInput,
                timeScale: timeScaleInput,
                workDaysName: workDaysInput,
                workDaysIndex: JSON.stringify(dayIndex)
            };
            api.updateSchedulerSettings(data)
                .then((_data) => {
                    setWorkHours(w => ({ ...w, ...workHoursInput }));
                    setTimeScale(t => ({ ...t, ...timeScaleInput }));
                    setWorkDays(data.workDaysIndex);
                    global.emitter.emit('change', _data.type, _data.message);

                })
                .catch()
        }
    }

    function calculateHours() {
        // Başlangıç saati ve bitiş saati dakika cinsine çevir
        var startHourParts = workHours.start.split(":");
        var endHourParts = workHours.end.split(":");
        var startHourMinutes = parseInt(startHourParts[0]) * 60 + parseInt(startHourParts[1]);
        var endHourMinutes = parseInt(endHourParts[0]) * 60 + parseInt(endHourParts[1]);

        // Periyotu dakikaya çevir
        var periyotMinutes = parseInt(timeScale.interval);
        // Başlangıç saatinde periyotu çıkar
        var newStartHourMinutes = startHourMinutes - periyotMinutes * 2;
        // Negatif sonuç durumunu kontrol et
        if (newStartHourMinutes < 0) {
            newStartHourMinutes = 0; // 1440 dakika bir günü temsil eder
        }

        // Bitiş saatinde periyotu ekle
        var newEndHourMinutes = endHourMinutes + periyotMinutes * 3;
        // Negatif sonuç durumunu kontrol et
        if (newEndHourMinutes > 1440) {
            newEndHourMinutes = 1440; // 1440 dakika bir günü temsil eder
        }

        // Sonuçları saat ve dakika olarak formatla
        var newStartHour = ("0" + Math.floor(newStartHourMinutes / 60)).slice(-2) + ":" + ("0" + (newStartHourMinutes % 60)).slice(-2);
        var newEndHour = ("0" + Math.floor(newEndHourMinutes / 60)).slice(-2) + ":" + ("0" + (newEndHourMinutes % 60)).slice(-2);

        return {
            start: newStartHour,
            end: newEndHour
        }
    };

    const getScheduleData = async (startDate, endDate) => {
        var b = {
            "params": {
                "StartDate": startDate,
                "EndDate": endDate
            },
            "StartDate": startDate,
            "EndDate": endDate
        }

        try {
            const fetchResult = fetch(`${metrics.URL}/getScheduleData/?ekipId=${ekipInput}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),

                },
                body: JSON.stringify(b)
            });

            const response = await fetchResult;
            // console.log("res:", response.json());
            return response.json();
        } catch (e) {
            console.warn(e);
        }

    };

    function areAllArraysEmpty(arr) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].length > 0) {
                return false;
            }
        }
        return true;
    }

    const copyWeek = () => {
        var startDate = moment(scheduleObj.current.getCurrentViewDates()[0]).toISOString();
        var endDate = moment(scheduleObj.current.getCurrentViewDates()[6]).add(1, "day").toISOString();

        var apiArray = [];
        for (let i = 1; i <= copyWeekInput; i++) {
            apiArray.push(getScheduleData(moment(startDate).add(i, "week").toISOString(), moment(endDate).add(i, "week").toISOString()))
        }
        Promise.all(apiArray)
            .then((arr) => {

                if (areAllArraysEmpty(arr)) {
                    getScheduleData(startDate, endDate)
                        .then(data => {
                            if (data.length > 0) {
                                data.forEach(obj => {
                                    delete obj.__v;
                                    delete obj._id;
                                    for (let index = 1; index <= copyWeekInput; index++) {
                                        obj.StartTime = moment(obj.StartTime).add(1, "week").toDate()
                                        obj.EndTime = moment(obj.EndTime).add(1, "week").toDate();
                                        scheduleObj.current.addEvent(obj);
                                    }
                                });
                                global.emitter.emit('change', 'success', 'Kopyalama işlemi başarılı.');
                            } else {
                                global.emitter.emit('change', 'warning', 'Kopyalanacak veri bulunamadı.');

                            }
                        })
                } else {
                    global.emitter.emit('change', 'warning', 'İleri tarihlerde randevu bulunduğundan kopyalama işlemi yapılamadı.');
                }
            });
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <ScheduleComponent
                cssClass='schedule-cell-dimension'
                height={'85vh'}
                width='78%'
                locale='tr'
                views={['Week']}
                eventSettings={{ dataSource: dataManger }}
                startHour={calculateHours().start}
                endHour={calculateHours().end}
                workHours={workHours}
                timeScale={timeScale}
                allowResizing={false}
                firstDayOfWeek={1}
                workDays={workDays}
                quickInfoTemplates={{
                    header: headerTemplate,
                    content: contentTemplate,
                    footer: footerTemplate
                }}
                editorTemplate={editorTemplate}
                ref={scheduleObj}
            >
                <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
            </ScheduleComponent>

            {/* Ayarlar */}

            <TableContainer component={Paper} sx={{ width: '22%', marginLeft: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Table style={{ width: "100%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Ayarlar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Ekip Seçimi
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div style={{ width: '150px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <TextField
                                        select={true}
                                        value={ekipInput}
                                        style={{ width: '100%' }}
                                        onChange={(event) => {
                                            setEkipInput(event.target.value)
                                        }}
                                    >
                                        {ekipList.map((option) => (
                                            <MenuItem key={option._id} value={option._id} name={option.ekipName}>
                                                {option.ekipName}
                                            </MenuItem>
                                        )
                                        )}
                                    </TextField>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Çalışma Günleri
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div style={{ width: '150px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <TextField
                                        select={true}
                                        value={workDaysInput}
                                        style={{ width: '100%' }}
                                        onChange={(event) => {
                                            setWorkDaysInput(event.target.value);
                                        }}
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: (selected) => (selected.join(","))
                                        }}
                                    >
                                        {weekInput.map((option) => (
                                            <MenuItem key={option.value} value={option.name} name={option.value}>
                                                <Checkbox checked={workDaysInput.indexOf(option.name) > -1} />
                                                {option.name}
                                            </MenuItem>
                                        )
                                        )}
                                    </TextField>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Çalışma Saatleri
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div style={{ width: '150px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <TextField
                                        select={true}
                                        value={workHoursInput.start}
                                        onChange={(event) => setWorkHoursInput({ ...workHoursInput, start: event.target.value })}
                                    >
                                        {hours.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        )
                                        )}
                                    </TextField>
                                    {" - "}
                                    <TextField
                                        select={true}
                                        value={workHoursInput.end}
                                        onChange={(event) => setWorkHoursInput({ ...workHoursInput, end: event.target.value })}
                                    >
                                        {hours.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        )
                                        )}
                                    </TextField>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Periyot
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    type='number'
                                    sx={{ width: '45px' }}
                                    InputProps={{ inputProps: { min: 5 } }}
                                    onChange={(e) => { setTimeScaleInput({ ...timeScaleInput, interval: e.target.value }) }}
                                    value={timeScaleInput.interval}
                                /> {" dk."}
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{}}
                        >

                            <TableCell component="th" scope="row" colSpan={2}>
                                <Button disabled={!ekipInput} onClick={saveSettingsHandler} style={{ float: 'right' }}>KAYDET</Button>
                            </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                    Bu haftayı önümüzdeki
                    <TextField
                        select={true}
                        style={{ marginLeft: 5, marginBottom: 3 }}
                        value={copyWeekInput}
                        onChange={(event) => setCopyWeekInput(event.target.value)}
                    >
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        )
                        )}
                    </TextField> haftaya
                    <Button onClick={copyWeek}>Kopyala</Button>
                </div>
            </TableContainer>
        </div>
    )
}

export default App