import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#006fba',
        },
        secondary: {
            main: '#000',
        },

        error: {
            main: red.A400,
        },
        background: {
            default: '#f5f5f5',
        },
        textSecondary: {
            main: '#fff',
        },
    },
    input: {
        "& input.Mui-disabled": {
            color: "#000"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                },
            },
        }
    }
});

export default theme;
