import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polygon, FeatureGroup, Popup, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Commons from '../../functions/Commons';
import iller from '../../iller_lat_lon.json';
import polyil from './testGeoJSON.json'; // DB'ye tüm ilçeler eklenince TRGeoJSON.json import edilecek.
import metrics from '../../metrics';
import "./selectArea.css";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const SelectArea = ({ changeMapList }) => {
    // State ve useRef tanımlamaları
    const [center, setCenter] = useState([]); // Harita merkezini saklamak için state
    const [zoom, setZoom] = useState(8); // Harita yakınlaştırma düzeyini saklamak için state
    const [count, setCount] = useState(0); // Sayacı saklamak için state
    const [selectedCity, setSelectedCity] = useState(null); // Seçilen şehri saklamak için state
    const [polyArray, setPolyArray] = useState(polyil); // Çizilen poligonların dizisini saklamak için state
    const [prevList, setPrevList] = useState([]); // Önceki liste verilerini saklamak için state
    const [selectedCitiesFromUser, setSelectedCitiesFromUser] = useState([]); // Kullanıcının seçtiği şehirleri saklamak için state

    const mapRef = useRef(null); // Leaflet haritasını referans olarak saklamak için useRef
    const popupElRef = useRef(null); // Leaflet Popup'ını referans olarak saklamak için useRef

    // useEffect, bileşen ilk render olduğunda şehir merkezini ayarlar
    useEffect(() => {
        const city = Commons.getUserCity(); // Kullanıcının şehir bilgisini alır
        var cityCenter = iller.find(il => il.il_adi === city.toLocaleUpperCase('TR')); // Şehir merkez koordinatlarını bulur
        if (cityCenter) {
            setCenter([cityCenter.lat, cityCenter.lng]); // Merkezi belirler

        } else {
            setCenter([39.82, 34.8044444]); // Merkezi varsayılan olarak belirler
            setZoom(7); // Yakınlaştırmayı varsayılan olarak belirler
        }

    }, []);

    // Poligon stil seçenekleri
    const polygonOptions = {
        fillOpacity: 0, // Normal dolgu opaklığı
        weight: 2, // Normal stroke genişliği
        stroke: true // Stroke'u aktifleştirme
    };

    // Poligonun üzerine gelindiğinde çalışacak olay işleyicisi
    const handleMouseOver = (e) => {
        const layer = e.target;
        layer.setStyle({
            fillColor: 'blue', // Mouse üzerindeyken dolgu rengini değiştirme
            fillOpacity: 0.3, // Mouse üzerindeyken dolgu opaklığını değiştirme
            weight: 1, // Mouse üzerindeyken stroke genişliğini değiştirme
            stroke: true
        });
    };

    // Poligonun üzerinden çıkıldığında çalışacak olay işleyicisi
    const handleMouseOut = (e) => {
        const layer = e.target;
        layer.setStyle(polygonOptions); // Mouse çıkınca renk değiştirme
    };

    // "Geri Al" butonuna tıklandığında çalışacak işlev
    const onHandleBack = () => {
        if (count === 1) {
            setPolyArray(polyil) // Poligonları önceki duruma geri alır
        } else if (count === 2) {
            setPolyArray(prevList); // Poligonları daha önceki duruma geri alır
        }
        setCount(count - 1); // Sayacı azaltır
        popupElRef?.current?._closeButton.click() // Popup'ı kapatır
    };

    // Ekle/Kaldır butonuna tıklanınca çalışacak işlev
    const handleAddRemoveCity = () => {
        // Mevcut seçilen şehirleri kopyalıyoruz.
        const tempSelectedCities = [...selectedCitiesFromUser];

        // Eğer şehir zaten ekli ise, kaldırmak için filtreliyoruz.
        // Aksi takdirde, şehri eklemek için diziyi güncelliyoruz.
        if (selectedCity.added) {
            const filteredCities = tempSelectedCities.filter(city => city.properties.id !== selectedCity.data.properties.id);
            setSelectedCitiesFromUser(filteredCities);
            changeMapList(filteredCities); // Seçilen şehirleri Tablonun "Ekle" dialogunda göstermek için her değişiklikte changeMapList prop'unu çağırdık.

            // Seçili şehri polyArray dizisine ekliyoruz.
            setPolyArray(prevPolyArray => [...prevPolyArray, selectedCity.data]);
        } else {
            tempSelectedCities.push(selectedCity.data);
            setSelectedCitiesFromUser(tempSelectedCities);
            changeMapList(tempSelectedCities); // Seçilen şehirleri Tablonun "Ekle" dialogunda göstermek için her değişiklikte changeMapList prop'unu çağırdık.

            // Seçili şehri polyArray dizisinden çıkarıyoruz.
            setPolyArray(prevPolyArray => prevPolyArray.filter(city => city.properties.id !== selectedCity.data.properties.id));
        }

        // Popup'ı kapatıyoruz.
        popupElRef?.current?._closeButton.click();
    }

    return (
        <div style={{ width: "100%" }}>
            {/* <button onClick={()=>{console.log(selectedCitiesFromUser)}}>cs</button> */}
            {
                center.length > 0 &&
                (
                    <div>
                        {/* Harita bileşeni */}
                        <MapContainer style={{ height: "85vh", width: '100%', zIndex: 2, position: "relative !important" }} center={center} zoom={zoom} scrollWheelZoom={true} ref={mapRef} >
                            {/* OpenStreetMap TileLayer */}
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <FeatureGroup>

                                {/* Seçili şehir varsa, Popup oluşturur */}
                                {
                                    selectedCity &&
                                    <Popup ref={popupElRef} className='popup'>

                                        {/* Ekle/Kaldır butonu */}
                                        <button className='popupButton' onClick={handleAddRemoveCity}>
                                            {selectedCity && (selectedCity.added ? <span className='arBtn'><RemoveIcon fontSize='medium' />{selectedCity.data.properties.text} kaldır</span> : <span className='arBtn'><AddIcon fontSize='medium' /> {selectedCity.data.properties.text} ekle </span>)}
                                        </button>

                                        {/* Detay Göster butonu */}
                                        {count !== 2 && !selectedCity.added &&
                                            <button className='popupButton' onClick={() => {

                                                count === 0 && mapRef.current?._lastCenter && mapRef.current.setView([mapRef.current?._lastCenter?.lat, mapRef.current?._lastCenter?.lng], 9)
                                                // count === 1 && mapRef.current?._lastCenter && mapRef.current.setView([mapRef.current?._lastCenter?.lat, mapRef.current?._lastCenter?.lng], 10)

                                                fetch(`${metrics.URL}/getCityData`, {
                                                    method: 'POST',
                                                    headers: {
                                                        Accept: 'application/json',
                                                        'Content-Type': 'application/json',
                                                        authorization: Commons.getAuthorization(),
                                                    },
                                                    body: JSON.stringify({
                                                        id: selectedCity.data.properties.id
                                                    })
                                                }).then(res => res.json())
                                                    .then(data => {
                                                        setPrevList(polyArray);
                                                        setPolyArray(data.coords.features);
                                                        setCount(count + 1);
                                                        popupElRef?.current?._closeButton.click()
                                                    });
                                                // setMenuPos(true);
                                            }}><span className='arBtn'><ZoomInIcon fontSize='medium' /> Detay Göster</span></button>
                                        }
                                    </Popup>
                                }

                                {/* Dizideki poligonları haritada gösterir */}
                                {
                                    polyArray.length > 0 && polyArray.map((il, index) => {
                                        return (
                                            <GeoJSON
                                                key={il.properties.text + il.properties.id}
                                                pathOptions={polygonOptions}
                                                data={il}
                                                eventHandlers={{
                                                    mouseover: handleMouseOver,
                                                    mouseout: handleMouseOut,
                                                    click: () => {
                                                        // Şehrin adını büyük harfe çevirerek iller listesinde arar ve merkeze odaklar
                                                        var clickedCity = iller.find(sehir => sehir.il_adi.toLocaleLowerCase("tr").toLocaleUpperCase("en") === il.properties.text.toLocaleUpperCase());
                                                        if (clickedCity) {
                                                            mapRef.current.setView([clickedCity.lat, clickedCity.lng], 8);
                                                        }
                                                        setSelectedCity({ data: il, added: false }); // Seçilen şehri günceller
                                                    },
                                                }}
                                            />
                                        )
                                    })
                                }

                                {/* Kullanıcının seçtiği şehirleri gösterir */}
                                {selectedCitiesFromUser.length > 0 &&
                                    selectedCitiesFromUser.map((il, index) => (
                                        <GeoJSON
                                            key={index}
                                            data={il}
                                            eventHandlers={{
                                                click: () => {
                                                    setSelectedCity({ data: il, added: true }); // Seçilen şehri günceller
                                                },
                                            }}
                                            fillOpacity={0.5}
                                            weight={1}
                                            stroke={true}
                                            fill={true}
                                            fillColor='red'
                                        />
                                    ))
                                }
                            </FeatureGroup>
                        </MapContainer>
                        {/* "Geri Al" butonu */}
                        <button disabled={count === 0} onClick={onHandleBack} style={{ position: "absolute", top: 15, left: 20, zIndex: 3, width: "80px", height: "30px" }}>Geri Al</button>
                    </div>
                )
            }
        </div>
    )
}

export default SelectArea;
