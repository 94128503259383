/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Divider } from '@mui/material';

class Separator extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  checkIsEmpty() {
    return false;
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'row',
          marginTop: 15,
          color: '#3c5898',
        }}
      >
        <Divider />
        <label
          style={{
            fontSize: '1.25rem',
            fontWeight: '500',
            lineHeight: '2.39',
            letterSpacing: '0.0075em',
            paddigleft: 30,
            marginTop: 8,
            marginBottom: 5,
          }}
        >
          {this.props.children}
        </label>
        <Divider />
      </div>
    );
  }
}

const styles = () => ({
  separatorContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 15,
  },
  separatorLine: {
    flex: 1,
    borderWidth: 1,
    height: 1,
    borderColor: '#9B9FA4',
  },
  separatorOr: {
    color: '#9B9FA4',
    marginHorizontal: 8,
  },
});
export default withStyles(styles)(Separator);
