import React from 'react'
import {
    Typography,
    Zoom,
    Box,
    Grid,
} from '@mui/material';
import logo from '../assets/logo01.png';
import colors from '../functions/colorScheme';
const DemoUserTableScreen = () => {
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                flexDirection: 'column',
                padding: 120,
                paddingTop: 80,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            <Zoom in timeout={500}>
                <Box style={styles.logoDiv} boxShadow={15}>
                    <img alt="" style={{ height: 200 }} src={logo} />
                </Box>
            </Zoom>
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography style={styles.text1} gutterBottom>
                    Aktivasyonunuz bulunmamaktadır veya kullanım süreniz sona ermiştir.
                </Typography>

                <Typography style={styles.text2} gutterBottom>
                    Denetmen Lab'ı kullanmaya devam etmek için lütfen bizimle iletişime geçiniz veya lisansınızı yenileyiniz.
                </Typography>

                <Grid container spacing={2} marginTop={5}>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} item xs={6} md={6}>
                        <Typography variant="caption" fontStyle='italic' fontSize={20} >
                            E-mail:  <a style={{ textDecoration: 'none', color: '#808080' }} href="mailto:info@shiftsoft.co">info@shiftsoft.co</a>
                        </Typography>
                    </Grid>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} item xs={6} md={6}>
                        <Typography variant="caption" fontStyle='italic' fontSize={20} >
                            Tel: <a style={{ textDecoration: 'none', color: '#808080' }} href="tel:+90 542 281 05 70">+90 542 281 05 70</a>
                        </Typography>
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}
const styles = {
    mainContainer: {
        // backgroundColor:'#f3f3f3',
        width: '100%',
        position: 'relative',
        height: '100vh',
        zoom: 0.9,
        overflow: 'hidden',
        boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px',
    },
    logoDiv: {
        flexDirection: 'row',
        display: 'flex',
        height: 200,
        width: 200,
        borderRadius: 200,
        overflow: 'hidden',
        alignItems: 'center',
        marginBottom: 30,
    },
    text1: {
        fontFamily: 'Roboto',
        color: '#525252',
        fontSize: 32,
        fontWeight: 300,
        textAlign: 'center',
        //boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px'
    },
    text2: {
        fontFamily: 'Roboto',
        color: '#808080',
        fontSize: 15,
        textAlign: 'center',
        //boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px'
    },
    bottomLine: {
        bottom: 0,
        position: 'absolute',
        height: 6,
        width: '100%',
        backgroundColor: colors.denetmen,
        backgroundImage: 'linear-gradient(to right, #6dc5e7, #8360c3)',
    },
};

export default DemoUserTableScreen