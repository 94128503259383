import * as React from 'react';
import TextField from '@mui/material/TextField';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/material/styles';

import trLocale from 'date-fns/locale/tr';

const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "rgb(50,50,50)",
        color: "rgb(50,50,50)"
    },
    ".MuiInputBase-input.Mui-disabled:hover": {
        cursor: "not-allowed"
    }
}));

export default function CustomDatetimeRangePicker(props) {
    const [value, setValue] = React.useState([null, null]);

    const handleChangeStart = (newValue) => {
        setValue([newValue, value[1]]);
    };
    const handleChangeEnd = (newValue) => {
        setValue([value[0], newValue]);
    };

    React.useEffect(() => {
        setValue(props.value);
    }, [props]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: "10px" }}>
                <DateTimePicker
                    label={props.label ? `${props.label} Baş.` : 'Başlangıç'}
                    value={value[0]}
                    onChange={handleChangeStart}
                    disablePast={true}
                    minutesStep={5}
                    cancelText='İptal'
                    clearText='Temizle'
                    disabled={props.disable}
                    // inputFormat="dd/MM/yyyy hh:mm"
                    renderInput={(params) => <CustomDisableInput style={{ width: '46%' }} {...params} />}
                />

                <SwapHorizIcon sx={{ marginTop: '15px' }} />

                <DateTimePicker
                    label={props.label ? `${props.label} Bitiş` : 'Bitiş'}
                    value={value[1]}
                    onChange={handleChangeEnd}
                    disablePast={true}
                    minutesStep={5}
                    cancelText='İptal'
                    clearText='Temizle'
                    disabled={props.disable}
                    // inputFormat="dd/MM/yyyy hh:mm"
                    renderInput={(params) => <CustomDisableInput style={{ width: '46%' }} {...params} />}
                />
            </div>

        </LocalizationProvider>
    );
}