/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';

import Draggable from 'react-draggable';
import Commons from '../functions/Commons';
import Api from '../functions/api';
import SingleFieldsModel from '../models/SingleFieldsModel';
import InputTypes from '../components/InputTypes';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggableTown" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
export default class TownCreateEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openDialog: props.openDialog,
            refList: [],
        };
        this.inputRefs = [];
        this.sendRequest = true;
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.openDialog;
        if (newProps.hasOwnProperty('openDialog') && newValue !== this.state.openDialog && newValue) {
            this.setState({
                openDialog: newValue,
            });
        }
    }

    saveOrUpdate(inputList) {
        try {
            this.sendRequest = true;
            var SingleFields = new SingleFieldsModel();

            inputList.forEach((item) => {
                if (this.inputRefs[item.dataKey].props.isRequired && !this.inputRefs[item.dataKey].state.value) {
                    this.sendRequest = false;
                }
                if (this.inputRefs[item.dataKey].checkIsEmpty()) {
                    SingleFields.SingleFields[item.dataKey] = this.inputRefs[item.dataKey].state.value;
                }
            });

            if (this.sendRequest) {
                if (this.props.formType === 1) {
                    //update
                } else if (this.props.formType === 2) {
                    //create

                    var cleanArray = Commons.removeFalsy(SingleFields.SingleFields);

                    Api.createTown(cleanArray)
                        .then((res) => {
                            SingleFields.SingleFields._id = res._id;
                            this.props.addItemTableData(SingleFields.SingleFields);
                            this.props.toggleDialog();
                            global.emitter.emit('change', 'success', 'Belediye Başarıyla Eklendi.');
                        })
                        .catch((err) => {
                            global.emitter.emit('change', 'error', 'Belediye Eklenemedi!');
                        });
                }
            } else {
                global.emitter.emit('change', 'warning', 'Zorunlu Alanlar Boş Bırakılamaz!');
            }
        } catch (error) {
            alert(error);
        }
    }

    render() {
        const { inputList, dialogTitle } = this.props;

        return (
            <Dialog
                open={this.props.openDialog}
                onClose={() => this.props.toggleDialog()}
                scroll="paper"
                maxWidth="xs"
                fullWidth
                PaperComponent={PaperComponent}
                aria-labelledby="draggableTown"
            >
                <DialogTitle style={{ cursor: 'move', textAlign: 'center', color: '#29487d' }} id="draggableTown">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent style={{ flexGrow: 1 }} dividers>
                    <Grid container justify="space-around">
                        {inputList.map((element, indx) => {
                            return InputTypes(element, {
                                inputRefs: this.inputRefs,
                            });
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.toggleDialog()} color="primary">
                        İptal
                    </Button>
                    <Button onClick={() => this.saveOrUpdate(inputList)} color="primary">
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
