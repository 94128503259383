/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-useless-escape */

import Commons from '../functions/Commons'
import metrics from '../metrics';
export default {
  async getCityDetails(cityId) {
    try {
      const fetchResult = fetch(`https://businessyds.csb.gov.tr/api/department/findAllPublicStatistics/${cityId}`, {
        "headers": {
          "accept": "application/json, text/plain, */*",
          "accept-language": "tr-TR,tr;q=0.9,en-US;q=0.8,en;q=0.7",
          "sec-ch-ua": "\"Chromium\";v=\"94\", \"Google Chrome\";v=\"94\", \";Not A Brand\";v=\"99\"",
          "sec-ch-ua-mobile": "?0",
          "sec-ch-ua-platform": "\"Windows\"",
          "sec-fetch-dest": "empty",
          "sec-fetch-mode": "cors",
          "sec-fetch-site": "same-site"
        },
        "referrer": "https://yds.csb.gov.tr/",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": null,
        "method": "GET",
        "mode": "cors",
        "credentials": "omit"
      });
      const response = await fetchResult;

      return response.json();
    } catch (e) {
      console.warn(e);
    }
  },
  async getCityLabs(cityId, skip = 0, take = 0) {
    try {
      const fetchResult = fetch("https://businessyds.csb.gov.tr/api/department/findAllLabPublic", {
        "headers": {
          "accept": "application/json, text/plain, */*",
          "accept-language": "tr-TR,tr;q=0.9,en-US;q=0.8,en;q=0.7",
          "content-type": "application/json",
          "sec-ch-ua": "\"Chromium\";v=\"94\", \"Google Chrome\";v=\"94\", \";Not A Brand\";v=\"99\"",
          "sec-ch-ua-mobile": "?0",
          "sec-ch-ua-platform": "\"Windows\"",
          "sec-fetch-dest": "empty",
          "sec-fetch-mode": "cors",
          "sec-fetch-site": "same-site"
        },
        "referrer": "https://yds.csb.gov.tr/",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "body": `{\"requireTotalCount\":true,\"searchOperation\":\"contains\",\"searchValue\":null,\"skip\":${skip},\"take\":${take},\"userData\":{},\"sort\":[{\"selector\":\"fileNumber\",\"desc\":false}],\"filter\":[\"locationId\",\"=\",${cityId}]}`,
        "method": "POST",
        "mode": "cors",
        "credentials": "omit"
      });
      const response = await fetchResult;

      return response.json();
    } catch (e) {
      console.warn(e);
    }
  },
  async signUpVerify(jsonData) {
    try {
      const fetchResult = fetch(`${metrics.URL}/signUpVerify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: Commons.getAuthorization(),
        },
        body: JSON.stringify(jsonData),
      });
      const response = await fetchResult;

      return response.json();
    } catch (e) {
      console.warn(e);
    }
  },
  async getVKNInfo(jsonData) {
    try {
      const fetchResult = fetch(`${metrics.URL}/getVKNInfo`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: Commons.getAuthorization(),
        },
        body: JSON.stringify(jsonData),
      });
      const response = await fetchResult;

      return response.json();
    } catch (e) {
      console.warn(e);
    }
  },
  async startDemoProcess(jsonData) {
    try {
      const fetchResult = fetch(`${metrics.URL}/startDemoProcess`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: Commons.getAuthorization(),
        },
        body: JSON.stringify(jsonData),
      });
      const response = await fetchResult;

      return response.json();
    } catch (e) {
      console.warn(e);
    }
  },

}