/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import moment from 'moment';
import trLocale from 'date-fns/locale/tr';
import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { styled } from '@mui/material/styles';

import 'moment/locale/tr';
import Commons from '../functions/Commons';
moment.locale('tr'); // it is required to select default locale manually

const CustomDisableInput = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "rgb(50,50,50)",
        color: "rgb(50,50,50)"
    },
    ".MuiInputBase-input.Mui-disabled:hover": {
        cursor: "not-allowed"
    }
}));

export default class CustomDatepicker extends React.Component {
    // The first commit of Material-UI
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? new Date(props.value) : null,
            error: false,
            open: false,
            required: this.props.isRequired
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.value;
        if (newProps.hasOwnProperty('value') && newValue !== this.state.value && newValue) {
            this.setState({
                value: new Date(newValue),
            });
        }
    }

    handleChange(date) {
        const val = Commons.isNoneValue(date) ? date : new Date(date);
        this.setState({
            value: val,
            error: false
        });
        this.props.onChange && this.props.onChange(val);
    }
    handleDialog(value) {
        this.setState({ open: value });
    }
    checkIsEmpty() {
        if (this.state.value) {
            this.setState({ error: false });
            return true;
        } else {
            if (this.state.required) {
                this.setState({ error: true });
                return false;
            }

            return true;
        }
    }

    render() {
        const { label, editable } = this.props;
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={trLocale}>
                <DesktopDatePicker
                    label={label}
                    value={this.state.value}
                    disableOpenPicker={true}
                    open={false}
                    // OpenPickerButtonProps={{
                    //   style: { color: 'purple' },
                    //   onClick: () => this.handleDialog(true), //TODO
                    // }}
                    inputFormat="dd/MM/yyyy"
                    onChange={(val) => this.handleChange(val)}
                    renderInput={(params) => (
                        <div style={{ display: 'flex' }}>
                            <CustomDisableInput
                                {...params}
                                fullWidth
                                style={this.props.style || { width: '100%' }}
                                margin="normal"
                                required={this.state.required}
                                disabled={!editable}
                                error={this.state.error}
                                onKeyDown={(e) => { !editable && e.preventDefault(); }} // klavyeden girişi kapatıyor.
                                InputProps={{
                                    // readOnly: true, // Çalışmıyor
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => this.handleDialog(true)}>
                                                <EventIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    )}
                />
                <div style={{ visibility: 'hidden', height: 0 }}>
                    <MobileDatePicker
                        label={label}
                        inputFormat="dd/MM/yyyy"
                        disabled={!editable}

                        renderInput={(props) => (
                            <TextField
                                {...props}
                                label={label}
                                fullWidth
                                style={this.props.style || { width: '100%' }}
                                margin="normal"
                                error={this.state.error}
                            />
                        )}
                        DialogProps={{
                            open: editable ? this.state.open : false,
                        }}
                        required={this.state.required}
                        cancelText="İptal"
                        clearText="Temİzle"
                        disableCloseOnSelect={false}
                        okText="Tamam"
                        clearable
                        value={this.state.value}
                        onChange={(val) => this.handleChange(val)}
                        onClose={() => this.handleDialog(false)}
                        onOpen={() => this.handleDialog(true)}
                    />
                </div>
            </LocalizationProvider>
        );
    }
}
