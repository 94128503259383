import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CustomCheckBox = (props) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <FormGroup style={props.style || { appearance: 'none', color: "#343434" }}>
      <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={props.label} style={{alignSelf: 'flex-end'}} />
    </FormGroup>
  );
};

export default CustomCheckBox;
