/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';
import Draggable from 'react-draggable';
import Commons from '../functions/Commons';
import Api from '../functions/api';
import EkipModel from '../models/EkipModel';
import InputTypes from '../components/InputTypes';
import SelectOnMap from '../components/SelectOnMap';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggableOutgoing" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
export default class EkipCreateEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openDialog: props.openDialog,
            refList: [],
            mapDialogOpen: false,
        };
        this.inputRefs = [];
        this.sendRequest = true;
        this.coords = [];
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.openDialog;
        if (newProps.hasOwnProperty('openDialog') && newValue !== this.state.openDialog && newValue) {
            this.setState({
                openDialog: newValue,
            });
        }
    }

    saveOrUpdate(inputList) {
        try {
            console.log("test12")
            this.sendRequest = true;
            var Ekip = new EkipModel();

            inputList.forEach((item) => {
                if (this.inputRefs[item.dataKey].props.isRequired && !this.inputRefs[item.dataKey].state.value) {
                    this.sendRequest = false;
                }
                if (this.inputRefs[item.dataKey].checkIsEmpty()) {
                    Ekip.Ekip[item.dataKey] = this.inputRefs[item.dataKey].state.value;
                }
            });
            if (this.sendRequest) {
                if (this.props.formType === 1) {
                    Ekip.Ekip._id = this.props.rowId;
                    Ekip.Ekip.selectedCoordinates = this.coords;
                    Api.updateEkip(Ekip.Ekip)
                        .then((res) => {
                            this.props.updateTableData(Ekip.Ekip);
                            this.props.toggleDialog();
                            global.emitter.emit('change', res.type, res.message);
                        })
                        .catch(() => {
                            global.emitter.emit('change', 'error', 'İşlem Başarısız!');
                        });
                    //update
                } else if (this.props.formType === 2) {
                    //create
                    Ekip.Ekip.selectedCoordinates = this.coords;
                    var cleanArray = Commons.removeFalsy(Ekip.Ekip);

                    Api.createEkip(cleanArray)
                        .then((res) => {
                            Ekip.Ekip._id = res._id;
                            this.props.addItemTableData(Ekip.Ekip);
                            this.props.toggleDialog();
                            global.emitter.emit('change', res.type, res.message);
                        })
                        .catch(() => {
                            global.emitter.emit('change', 'error', 'İşlem Başarısız!');
                        });
                }
            } else {
                global.emitter.emit('change', 'warning', 'Zorunlu Alanlar Boş Bırakılamaz!');
            }
        } catch (error) {
            //alert(error)
        }
    }

    changeMapList = (list) => {
        console.log("list:", list)
        this.inputRefs["selectedArea"].state.value = list.map((il) => il.properties.text).join();
        this.coords = list;
    }

    render() {
        const { inputList, dialogTitle } = this.props;

        return (
            <div>
                <Dialog
                    open={this.props.openDialog}
                    onClose={() => this.props.toggleDialog()}
                    scroll="paper"
                    maxWidth="sm"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggableOutgoing"
                >
                    <DialogTitle style={{ cursor: 'move', textAlign: 'center', color: '#29487d' }} id="draggableOutgoing">
                        {dialogTitle}
                    </DialogTitle>
                    <DialogContent style={{ flexGrow: 1 }} dividers>
                        <Grid container justify="space-around">
                            {inputList.map((element) => {
                                if (element.colVisible) {
                                    return InputTypes(element, { inputRefs: this.inputRefs });
                                } else {
                                    return <></>
                                }
                            })}
                            <button onClick={() => {
                                this.setState({ mapDialogOpen: true }); this.inputRefs["selectedArea"].state.value = "";
                            }}>Haritayı Aç</button>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.toggleDialog()} color="primary">
                            İptal
                        </Button>
                        <Button onClick={() => this.saveOrUpdate(inputList)} color="primary">
                            Kaydet
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Harita Dialog */}
                <Dialog
                    open={this.state.mapDialogOpen}
                    onClose={() => this.setState({ mapDialogOpen: false })}
                    // scroll="paper"
                    fullScreen
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggableOutgoing"
                >
                    <DialogTitle style={{ cursor: 'move', textAlign: 'center', color: '#29487d' }} id="draggableOutgoing">
                        {"Alan Seçimi"}
                    </DialogTitle>
                    <DialogContent style={{ flexGrow: 1 }} dividers>
                        <Grid container justify="space-around">
                            <SelectOnMap changeMapList={this.changeMapList} />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.inputRefs["selectedArea"].state.value = "";
                            this.setState({ mapDialogOpen: false });
                        }} color="primary">
                            İptal
                        </Button>
                        <Button onClick={() => { this.setState({ mapDialogOpen: false }) }} color="primary">
                            Kaydet
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
