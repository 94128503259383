/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';

import Draggable from 'react-draggable';
import Commons from '../functions/Commons';
import Api from '../functions/api';
import md5 from 'md5';
import UserModel from '../models/userModel';
import InputTypes from '../components/InputTypes';
import sessionwrapper from '../functions/sessionwrapper'

function PaperComponent(props) {
    return (
        <Draggable handle="#draggableUser" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
export default class UserCreateEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openDialog: props.openDialog,
            refList: [],
        };
        this.inputRefs = [];
        this.sendRequest = true;
        this.roleControl = true;
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.openDialog;
        if (newProps.hasOwnProperty('openDialog') && newValue !== this.state.openDialog && newValue) {
            this.setState({
                openDialog: newValue,
            });
        }
        const newFormType = newProps.formType;
    }

    saveOrUpdate(inputList) {
        try {
            this.sendRequest = true;
            var User = new UserModel();

            const oldPassVal = inputList.find((val) => val.dataKey === 'password').value;
            const inputPassVal = this.inputRefs['password'].state.value;

            inputList.forEach((item) => {
                if (this.inputRefs[item.dataKey].props.isRequired && !this.inputRefs[item.dataKey].state.value) {
                    this.sendRequest = false;
                }
                if (this.inputRefs[item.dataKey].checkIsEmpty()) {
                    User.User[item.dataKey] = this.inputRefs[item.dataKey].state.value;
                }
                if (item.dataKey === "role") {
                    this.roleControl = Commons.checkAccessControl(item.value)
                }
            });
            if (oldPassVal !== inputPassVal) User.User.password = md5(inputPassVal);

            if (this.sendRequest) {
                if (this.roleControl) {
                    if (this.props.formType === 1) {
                        User.User._id = this.props.rowId;

                        Api.updateUser(User.User)
                            .then((res) => {
                                this.props.updateTableData(User.User);
                                this.props.toggleDialog();
                                global.emitter.emit('change', 'success', 'İşlem Başarılı!');
                            })
                            .catch((err) => {
                                global.emitter.emit('change', 'error', 'İşlem Başarısız!');
                            });
                        //update
                    } else if (this.props.formType === 2) {
                        //create

                        var cleanArray = Commons.removeFalsy(User.User);

                        Api.createUser(cleanArray)
                            .then((res) => {
                                User.User._id = res._id;
                                this.props.addItemTableData(User.User);
                                this.props.toggleDialog();
                                console.log(cleanArray);
                                global.emitter.emit('change', 'success', 'İşlem Başarılı!');
                            })
                            .catch((err) => {
                                global.emitter.emit('change', 'error', 'İşlem Başarısız!');
                            });
                    }
                } else {
                    global.emitter.emit('change', 'warning', 'Bu İşlemi Yapmak İçin Yetkiniz Yok!');
                }

            } else {
                global.emitter.emit('change', 'warning', 'Zorunlu Alanlar Boş Bırakılamaz!');
            }
        } catch (error) {
            alert(error);
        }
    }

    render() {
        const { inputList, dialogTitle } = this.props;
        const isEditable = Commons.checkAccessControl(inputList[8].value) ? true : false;
        return (
            <Dialog
                open={this.props.openDialog}
                onClose={() => this.props.toggleDialog()}
                scroll="paper"
                maxWidth="sm"
                PaperComponent={PaperComponent}
                aria-labelledby="draggableUser"
            >
                <DialogTitle style={{ cursor: 'move', textAlign: 'center', color: '#29487d' }} id="draggableUser">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent style={{ flexGrow: 1 }} dividers>
                    <Grid container justify="space-around">
                        {inputList.map((element, indx) => {
                            element.editable = isEditable
                            return InputTypes(element, {
                                inputRefs: this.inputRefs,
                            });
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.toggleDialog()} color="primary">
                        İptal
                    </Button>
                    <Button onClick={() => this.saveOrUpdate(inputList.filter((val) => val.inputVisible !== false))} color="primary">
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
