/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import isjs from 'is_js';
const ErLocal = 'http://localhost:2735';
const ErLocalD = 'http://localhost:2730';
const ErLocalIP = 'http://192.168.1.26:2735';
const Url = 'https://labapi.denetmen.app';
const UrlD = 'https://testapi.denetmen.app';

export default {
    URL: Url,
    URLD: UrlD,
    tableHeight: isjs.firefox() ? window.outerHeight - 195 : 'calc(100vh - 118px)',
    isFirefox: isjs.firefox()
};
