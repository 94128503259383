import { makeAutoObservable } from 'mobx';
import { configure } from 'mobx';

configure({
  // Since strict-mode is enabled, changing (observed) observable values without using an action is not allowed. Tried to modify: ChatStore@1.selectedRoom
  enforceActions: 'never',
});
class DialogStore {
  constructor() {
    this.leftFunc = () => {
      this.open = false;
    };
    this.rightFunc = null;
    this.open = false;
    this.dialogTitle = 'Başlık';
    this.leftButtonText = 'İptal';
    this.rightButtonText = 'Kaydet';
    this.maxWidth = 'sm';
    this.fullWidth = false;
    this.content = null;
    this.setOpen = (boolean) => {
      this.open = boolean;
    }
    makeAutoObservable(this);
  }


  setDialogProps() {

  }
}

const dialogStore = new DialogStore();
export default dialogStore;
