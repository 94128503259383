import React from 'react';
import { withStyles } from '@mui/styles';
import usersInputs from '../../inputfields/usersInputs.json';
import userCreateEdit from '../../dialogs/UserCreateEdit';
import CustomTable from '../../components/CustomTable';
import { Paper } from '@mui/material';
import { AutoResizer } from 'react-base-table';
import Text from 'react-texty';
import metrics from '../../metrics';
import moment from 'moment';
import DemoUserTableScreen from '../DemoUserTableScreen'
import Commons from '../../functions/Commons'

const API = {
    getApi: 'getUsers',
    deleteApi: 'deleteUser',
};
const stringRenderer = ({ className, cellData }) => (
    <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
        {cellData ? String(cellData) : cellData === false ? 'false' : cellData}
    </Text>
);

const dateRenderer = ({ className, cellData }) => (
    <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
        {cellData ? moment(cellData).format('DD/MM/YYYY') : ""}
    </Text>
);
const renderers = {
    string: stringRenderer,
    date: dateRenderer
};

const Cell = (cellProps) => {
    const format = cellProps.column.componentType === 3 ? 'date' : (cellProps.column.format || 'string');
    const renderer = renderers[format] || renderers.string;

    return renderer(cellProps);
};

const components = {
    TableCell: Cell,
};
class Users extends React.Component {
    constructor(props) {
        super(props);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.columnList = usersInputs.filter((val) => val.colVisible === true);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        return (
            <Paper
                style={{
                    width: `100%`,
                    position: 'relative',
                    height: metrics.tableHeight,
                }}
            >
                {!Commons.getValidation().contract && <DemoUserTableScreen />}
                {Commons.getValidation().contract &&
                    <AutoResizer>
                        {({ width, height }) => (
                            <CustomTable
                                width={width}
                                height={height}
                                API={API}
                                components={components}
                                searchEnabled
                                inputList={usersInputs}
                                EditModal={userCreateEdit}
                            />
                        )}
                    </AutoResizer>
                }
            </Paper>
        );
    }
}

const styles = () => ({});
export default withStyles(styles)(Users);
