import { configure } from 'mobx';
configure({
  enforceActions: 'never',
});

class CompanyInfoStore {

  static currentCompany = {
    _id: '',
    companyName: '', //true
    companyPhone: '', //true
    companyEmail: '', //true
    companyAdress: '', //false
    companyTaxNo: '',
    taxOffice: '', //false
    documentNo: '', //true
    chamberInfo: '', //true
    chamberRegistrationNo: '',
  };

  static setCurrentCompany(data) {
    this.currentCompany = data;
  }

  static getCurrentCompany() {
    return this.currentCompany;
  }
}

export default CompanyInfoStore;
