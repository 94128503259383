/* eslint-disable import/no-anonymous-default-export */
import metrics from '../metrics';
import sessionwrapper from '../functions/sessionwrapper';
import Commons from './Commons';

export default {
    async createUser(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createUser`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async updateUser(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/updateUser`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response;
        } catch (e) {
            console.warn(e);
        }
    },
    async createOutgoing(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createOutgoing`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async updateOutgoing(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/updateOutgoing`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async createEkip(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createEkip`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async updateEkip(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/updateEkip`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async updateAppointments(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/updateAppointments`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },

    async createConreteComp(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createConcreteComp`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async createOutgoingType(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createOutgoingType`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    //Kişiler
    async updateContact(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/updateContact`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async createContact(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createContact`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async createTown(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createTown`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getTown() {
        try {
            const fetchResult = fetch(`${metrics.URL}/gettown`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },

    async getAppointmentsLocations() {
        try {
            const fetchResult = fetch(`${metrics.URL}/getAppointmentsLocations`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;
            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },

    async getNotifications(pageIndex, top) {
        const user = sessionwrapper.getItem('user');
        if (user) {
            try {
                const fetchResult = fetch(`${metrics.URL}/getNotifications/${user._id}/?$skip=${(pageIndex - 1) * 12}&$top=${top}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        authorization: Commons.getAuthorization(),
                    },
                });
                const response = await fetchResult;

                return response.json();
            } catch (e) {
                console.warn(e);
            }
        }
    },

    //Personel
    async pushPersonnel(yibfNo, jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/pushPersonnel/${yibfNo}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response;
        } catch (e) {
            console.warn(e);
        }
    },
    async deletePersonnel(yibfNo, tcNo) {
        try {
            const fetchResult = fetch(`${metrics.URL}/deletePersonnel/${yibfNo}/${tcNo}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response;
        } catch (e) {
            console.warn(e);
        }
    },
    //lokasyon

    async setLocaltion(jsonData, yibfno) {
        try {
            const fetchResult = fetch(`${metrics.URL}/setConstructionLocations/${yibfno}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response;
        } catch (e) {
            console.warn(e);
        }
    },

    async updatePersonnel(yibfNo, tcNo, jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/updatePersonnel/${yibfNo}/${tcNo}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response;
        } catch (e) {
            console.warn(e);
        }
    },
    async getCompanyInfos() {
        try {
            const fetchResult = fetch(`${metrics.URL}/getCompanyInfos`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getAgreement() {
        try {
            const fetchResult = fetch(`${metrics.URL}/getAgreement`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async acceptAgreement() {
        try {
            const fetchResult = fetch(`${metrics.URL}/acceptAgreement/${sessionwrapper.getItem('user')._id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },

    async getInvoiceTemplate() {
        try {
            const fetchResult = fetch(`${metrics.URL}/getInvoiceTemplate`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async createInvoiceReport(jsonData, cName, fileName) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createInvoiceReport`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;
            const blob = await response.blob();
            //const file = new File([blob], fileName, { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
            const file = new Blob([blob], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            let a = document.createElement('a');
            const fileURL = URL.createObjectURL(file);
            a.download = cName + '_' + jsonData.claimsNo + '_' + fileName + '.docx';
            a.href = fileURL;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (e) {
            console.warn(e);
        }
    },
    async createCompanyInfo(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/createCompanyInfo`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getProjects(yibfNo) {
        try {
            const fetchResult = fetch(`${metrics.URL}/getProjects/${yibfNo}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async createChatRoom(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/createChatRoom`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async sendMessage(jsonData) {
        // console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/insertMessage`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getUsers() {
        try {
            const fetchResult = fetch(`${metrics.URL}/getUsers`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;
            return response.json();
        } catch (err) {
            console.warn(err);
        }
    },
    async getPersonnelForChat() {
        try {
            const fetchResult = fetch(`${metrics.URL}/getPersonnelForChat`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;
            return response.json();
        } catch (err) {
            console.warn(err);
        }
    },
    async getChatRoomsForSpecificUser(userId, dbName) {
        try {
            const fetchResult = fetch(`${metrics.URL}/getRoomsForSpecificUser/${userId}/${dbName}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;
            return response.json();
        } catch (err) {
            console.warn(err);
        }
    },
    async getChatRoomForSpecificId(roomId, startIndex, messageAmount, additionalMessageAmount, currentUserId, dbName) {
        try {
            //console.log(`${metrics.URL}/getRoomsMessages/${roomId}/${startIndex}/${messageAmount}/${additionalMessageAmount}`)
            const fetchResult = fetch(
                `${metrics.URL}/getRoomsMessages/${roomId}/${startIndex}/${messageAmount}/${additionalMessageAmount}/${currentUserId}/${dbName}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        authorization: Commons.getAuthorization(),
                    },
                }
            );
            const response = await fetchResult;
            return response.json();
        } catch (err) {
            console.warn(err);
        }
    },
    async updateLastSeenMessagesByMessageId(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/updateLastSeenMessagesByMessageId`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async updateLastSeenMessagesByUserId(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/updateLastSeenMessagesByUserId`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async leaveGroup(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/leaveGroup`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async deleteChatRoom(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/deleteChatRoom`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getUserForId(ids, currentUserId, dbName) {
        //console.log(JSON.stringify(ids))
        try {
            const fetchResult = fetch(`${metrics.URL}/getUsersWithId/${ids}/${currentUserId}/${dbName}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async makeAdmin(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/makeAdmin`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async removeFromAdmins(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/removeFromAdmins`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async clearChatHistory(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/clearChatHistory`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getChatRoomForId(roomID, userID) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/getChatRoomForId/${roomID}/${userID}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async addPersonsToAGroup(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/addPersonsToAGroup`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getShiftSoftSupportUser() {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/getShiftSoftSupportUser`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getShiftSoftsChannels(userId) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/getShiftSoftsChannels/${userId}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },

    async getConstructionByYibf(yibfNo) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/getConstructionByYibf/${yibfNo}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async addAConstructionMessage(jsonData) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/addAConstructionMessage`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getSFDT(url) {
        //console.log(JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/getSFDT/?url=${url}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getUserDetailAndCompanyName(userId) {
        try {
            const fetchResult = fetch(`${metrics.URL}/getUserDetailAndCompanyName/${userId}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getAppointmentsCount() {
        try {
            const fetchResult = fetch(`${metrics.URL}/getAppointmentsCount`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async updateAppointment(jsonData) {
        console.log("json", JSON.stringify(jsonData))
        try {
            const fetchResult = fetch(`${metrics.URL}/updateAppointments`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async updateSchedulerSettings(jsonData) {
        try {
            const fetchResult = fetch(`${metrics.URL}/updateSchedulerSettings`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
                body: JSON.stringify(jsonData),
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
    async getSchedulerSettings() {

        try {
            const fetchResult = fetch(`${metrics.URL}/getSchedulerSettings`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                }
            });
            const response = await fetchResult;
            return response.json();
        } catch (e) {
            console.warn(e);
            console.log(e)
        }
    },
    async getEkip() {
        try {
            const fetchResult = fetch(`${metrics.URL}/getEkip`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.getAuthorization(),
                },
            });
            const response = await fetchResult;

            return response.json();
        } catch (e) {
            console.warn(e);
        }
    },
};
