import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './Layout';
import { SignUp, ValidateDemo } from './screens';
import history from './functions/history';
import sessionwrapper from './functions/sessionwrapper';
import routes from './functions/routes';
import SuspanseProgress from './components/SupanseProgress';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Commons from './functions/Commons';
import DemoUserTableScreen from './screens/DemoUserTableScreen';
import { useTheme } from '@mui/styles';
import { amber, green } from '@mui/material/colors';
import { EventEmitter } from 'fbemitter';
import './App.css'

global.emitter = new EventEmitter();

const loading = () => <SuspanseProgress />;

function App() {
    const [openAlert, setOpenAlert] = React.useState(false);
    const [variant, setVariant] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [update, setUpdate] = React.useState(false);
    const theme = useTheme();
    const AlertColor = {
        error: theme.palette.error.dark,
        info: theme.palette.primary.main,
        success: green[600],
        warning: amber[700],
    };

    function alertClose() {
        setOpenAlert(false);
    }

    React.useEffect(() => {
        var change = global.emitter.addListener('change', (variant, message) => {
            setVariant(variant);
            setMessage(message);

            setOpenAlert(true);
        });
        var updateListener = global.emitter.addListener('updateRole', () => {
            setUpdate(!update);
        });
        return () => {
            // emitter.removeAllListeners(); Tablo dinleyicilerini de siliyor.
            change.remove();
            updateListener.remove();
        };
    });
    function returnRouteRender(route, props) {
        if (sessionwrapper.getItem('user')) {
            return (
                Commons.getValidation().contract ? Commons.checkAccessControl(route.role) && <route.Component {...props} /> : <DemoUserTableScreen {...props} />
            )
        }
        else {
            return <Redirect
                to={{
                    pathname: '/login',
                    state: { from: props.location },
                }}
            />
        }
    }

    return (
        <Router history={history}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ zIndex: 99999999999999, minWidth: 270 }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={alertClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={alertClose}
                    severity={variant}
                    sx={{ width: '100%', backgroundColor: AlertColor[variant] }}
                    children={message}
                />
                {/* <MySnackbarContentWrapper message={message} onClose={alertClose} variant={variant} /> */}
            </Snackbar>
            <Switch>
                <Route exact path="/login" name="Giriş" render={(props) => <SignUp {...props} />} />
                <Route exact path="/validateDemo" name="Demo talebi" render={(props) => <ValidateDemo {...props} />} />
                <Layout>
                    <React.Suspense fallback={loading()}>
                        {routes.map((route, idx) => {
                            return route.Component ? (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact
                                    name={route.name}
                                    render={(props) => returnRouteRender(route, props)}
                                />
                            ) : null;
                        })}
                    </React.Suspense>
                </Layout>
            </Switch>
        </Router>
    );
}

export default App;
