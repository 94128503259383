/* eslint-disable no-redeclare */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@mui/styles';
import BaseTable, { SortOrder } from 'react-base-table';
import 'react-base-table/styles.css';
import '../customTable.scss';
import metrics from '../metrics';
import UTF8 from 'utf8';
import InputTypes from '../components/InputTypes';
import cloneDeep from 'lodash/cloneDeep';
import {
    Input,
    Button,
    InputAdornment,
    IconButton,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Popover,
    CircularProgress,
} from '@mui/material';
import {
    Delete as DeleteIcon,
    Add as AddIcon,
    ImportExport as ExportIcon,
    Search as SearchIcon,
    FilterList as FilterIcon,
} from '@mui/icons-material';
import Text from 'react-texty';
import 'react-texty/styles.css';
import ReactExport from 'react-data-export';
import Commons from '../functions/Commons';
import NumberFormat from 'react-number-format';
import { ceil } from 'lodash';
import moment from 'moment';
import dialogStore from '../store/DialogStore';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const defaultSort = {};

const TableCell = ({ className, cellData, column }) => {
    if (column.keyboardType === 'numeric') {
        return (
            <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
                <NumberFormat displayType="text" value={ceil(cellData, 2)} thousandSeparator="." decimalSeparator="," />
            </Text>
        )
    }
    else if (cellData === false) {
        return (
            <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
                false
            </Text>
        )
    }
    else if (column.componentType === 3) {
        return (
            <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
                {cellData ? moment(cellData).format('DD/MM/YYYY') : ""}
            </Text>
        )
    }
    else if (cellData) {
        return (
            <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
                {String(cellData)}
            </Text>
        )
    }
    else {
        return (
            <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
                {cellData}
            </Text>
        )
    }
}

const TableHeaderCell = ({ className, column }) => (
    <Text hideArrow className={className}>
        {column.title}
    </Text>
);
class CustomTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true,
            openDialog: false,
            openDeleteDialog: false,
            loadingMore: false,
            loadedAll: false,

            excellData: null,
            fullcount: 0,
            datacount: 0,
            anchorEl: null,
            sortState: defaultSort,
            isAddItem: true
        };
        this.pageIndex = 1;
        this.filter = {};
        this.search = '';
        this.consData = [];
        this.detailedRow = null;
        this.selectedItem = null;
        this.dialogTitle = 'Yeni Kayıt';
        this.formType = 1;
        this.filterInputRefs = [];
        this.filteredFields = props.modalInputList
            ? cloneDeep(
                props.modalInputList.getInputList(2).filter((val) => val.filtered === true && Commons.checkAccessControl(val.role))
            )
            : cloneDeep(props.inputList.filter((val) => val.filtered === true && Commons.checkAccessControl(val.role)));
        this.columnList = props.inputList.filter((val) => val.colVisible === true && Commons.checkAccessControl(val.role));
        this.API = props.API;
        this.order = '';
        this.table = null;
        this.isAppointment = false;
        this.updateTableData = this.updateTableData.bind(this);
    }

    getConstruction(pageIndex, search) {
        if (pageIndex === 1) {
            this.consData = [];
            this.setState({ data: this.consData });
        }
        //$skip=${(pageIndex - 1) * 12}&$top=${12}&$}
        fetch(`${metrics.URL}/${this.API.getApi}/?skip=${(pageIndex - 1) * 12}&top=${12}&order=${this.order ? JSON.stringify(this.order) : ''}&search=${UTF8.encode(search)}`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.checkToken(),
                },
                body: JSON.stringify(this.filter)
            }
        )
            .then((response) => response.json())

            .then((json) => {
                this.consData.push(...json.Items);

                this.setState({
                    data: this.consData,
                    loadingMore: false,
                    loading: false,
                    datacount: this.consData.length,
                    fullcount: json.Count,
                    loadedAll: this.consData.length === json.Count,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    loadMore = () => {
        this.setState({ loadingMore: true });
        this.pageIndex++;
        this.getConstruction(this.pageIndex, this.search);
    };

    handleEndReached = () => {
        const { loadedAll } = this.state;

        if (loadedAll) return;

        this.loadMore();
    };

    renderEmpty = () => {
        if (this.state.loading) return null;
        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                Veri Bulunamadı
            </div>
        );
    };

    renderOverlay = () => {
        const { loading, loadingMore } = this.state;
        const classes = this.props.classes;
        if (loadingMore)
            return (
                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        flex: 1,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            backgroundColor: 'white',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingHorizontal: 10,
                            marginBottom: 50,
                            flexDirection: 'row',
                            boxShadow: '0px 2px 16px -5px rgba(0,0,0,0.5)',
                        }}
                    >
                        <CircularProgress size={30} className={classes.progress} color="secondary" />
                        <label
                            style={{
                                marginRight: 15,
                                flex: 1,
                                display: 'flex',
                                height: '100%',
                            }}
                        >
                            Yükleniyor
                        </label>
                    </div>
                </div>
            );
        if (loading)
            return (
                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                    }}
                >
                    <CircularProgress size={30} className={classes.progress} color="secondary" />
                </div>
            );

        return null;
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.API.getApi;

        if (newProps.hasOwnProperty('year') && newProps.year !== this.props.year) {
            this.API.getApi = newValue;
            this.getConstruction(1, this.search);
        }
    }
    buildTableListeners() {
        global.emitter.addListener('addNewDataToTable', (location, data) => {
            if (window.location.pathname === location) {
                this.addItemTableData(data);
                // TODO bildirim
            }
        });
        global.emitter.addListener('updateTableData', (location, data) => {
            if (window.location.pathname === location) {
                this.updateTableData(data, true);
                // TODO bildirim
            }
        });
    }

    componentDidMount() {
        this._isMount = true;
        this.getConstruction(1, this.search);
        this.buildTableListeners();
    }
    componentWillUnmount() {
        this._isMount = false;
        if (this.props.modalInputList) {
            this.props.modalInputList.getInputList(2).forEach((item) => {
                item.value = null;
            });
        } else {
            this.props.inputList.forEach((item) => {
                item.value = null;
            });
        }
    }

    toggleDialog = () => {
        /* if (!this.state.openDialog)
           this.detailedRow = null
     */
        this.setState({ openDialog: !this.state.openDialog });
    };

    handleClickOpen = () => {
        this.setState({ openDeleteDialog: true });
    };
    handleClose = () => {
        this.setState({ openDeleteDialog: false });
    };

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClosePopUp = () => {
        this.setState({ anchorEl: null });
    };

    onRowDoubleClick(data) {
        if (!this.props.disableEdit) {
            this.props.inputList.forEach((val) => {
                val.value = null;
            });
            this.detailedRow = data;
            this.formType = 1;
            var keys = Object.keys(data.rowData);
            //beton
            if (this.props.modalInputList) {
                //beton için
                const fields = this.props.modalInputList.getInputList(1);
                const allfields = this.props.modalInputList.getInputList(2);
                allfields.forEach((val) => {
                    val.value = null;
                });
                fields.forEach((val) => {
                    val.value = null;
                });
                for (let index = 0; index < keys.length; index++) {
                    var found = allfields.find((val) => val.dataKey === keys[index]);
                    if (found) {
                        found.value = data.rowData[keys[index]];
                    }
                }
            }
            //beton
            else {
                for (let index = 0; index < keys.length; index++) {
                    var found = this.props.inputList.find((val) => val.dataKey === keys[index]);
                    if (found) {
                        found.value = data.rowData[keys[index]];
                    }
                }
            }
            var foundCol = this.props.inputList.filter((val) => val.selectedTitle === true);
            this.dialogTitle = foundCol.map(val => data.rowData[val.dataKey]).join(" - ");

            this.toggleDialog();
        }
    }
    onRowClick(data) {
        var selectedBoolean = false;
        //seçlileri bir diziye atıp onları false yapılıp tüm diziyi dönmeye gerek kalmayabilir

        this.state.data.forEach((element, index) => {
            index === data.rowIndex ? (element.selected = !element.selected) : (element.selected = false);
            if (element.selected === true) {
                selectedBoolean = true;
            }
        });
        if (selectedBoolean) {
            this.selectedItem = data;
        } else {
            this.selectedItem = null;
        }
        this.setState({ data: this.state.data });
    }
    deleteItem = () => {
        if (this.selectedItem.rowData.appointmentStatus) {
            if (this.selectedItem && this.selectedItem.rowData.appointmentStatus === "İptal Edildi") this.handleClickOpen();
            else {

                dialogStore.dialogTitle = 'İşlem Engellendi';
                dialogStore.content = 'Sadece iptal edilen randevuları silebilirsiniz.'
                dialogStore.open = true;
                dialogStore.rightButtonText = "Kapat";
                dialogStore.leftButtonText = "";
                dialogStore.rightFunc = () => { dialogStore.open = false }
            }
        } else if (this.selectedItem) {
            this.handleClickOpen()
        }
    };

    deleteItemDb = () => {
        if (this.selectedItem) {
            var resTemp = this.selectedItem.rowData._id;
            fetch(`${metrics.URL}/${this.API.deleteApi}/${resTemp}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: Commons.checkToken(),
                },
            })
                .then((response) => response.json())
                .then(() => {
                    this.consData = [];
                    var filtered = this.state.data.filter((val) => val._id !== this.selectedItem.rowData._id);
                    this.consData = filtered;
                    this.setState({ data: filtered });
                    global.emitter.emit('change', 'success', 'İşlem Başarılı!');
                    this.selectedItem = null;
                    this.handleClose();
                })
                .catch(() => {
                    alert('Bilinmeyen Bir Hata Oluştu');
                });
        } else {
            console.log('seçili item yok');
        }
    };

    exportExcell() {
        this.excellData = [];

        fetch(`${metrics.URL}/${this.API.getApi}?skip=${0}&top=${this.state.fullcount}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: Commons.checkToken(),
            },
            body: JSON.stringify(this.filter)
        })
            .then((response) => response.json())
            .then((json) => {
                json.Items.forEach((data) => {
                    delete data.ydsClaimsId;
                    if (data.receivedİronDia) data.receivedİronDia = data.receivedİronDia.join();
                    Object.keys(data).forEach(key => {
                        if (data[key] && data[key].length === 24 && moment(data[key]).isValid()) {
                            data[key] = moment(data[key]).format("DD/MM/YYYY");
                        }
                    })
                    if (data.constructionChiefPhone) {
                        data.constructionChiefPhone = data.constructionChiefPhone.join(' - '); //Array olarak geliyor.
                    }
                    if (data.constructionOwnerPhone) {
                        data.constructionOwnerPhone = data.constructionOwnerPhone.join(' - ');
                    }
                    this.excellData.push(data);
                });
                this.setState({ excellData: this.excellData });
                this.excellData = [];
                this.setState({ excellData: null }); //Bura önemli yanlış sanıp silme
            })
            .catch((err) => {
                console.log(err);
            });
    }
    addItem() {
        this.dialogTitle =
            this.props.inputList.length === 1
                ? `Yeni ${this.props.inputList[0]['title']} Ekle`
                : `Yeni ${Commons.returnDialogTitle()} Ekle`;

        this.formType = 2;
        this.props.inputList.forEach((val) => {
            val.value = null;
        });
        this.setState({
            isAddItem: true
        })
        this.toggleDialog();
    }

    searchItem(data) {
        this.consData = [];
        this.search = data;
        this.pageIndex = 1;
        this.setState({ loading: true });
        this.getConstruction(this.pageIndex, this.search);
    }

    _rowClassName = ({ rowData, rowIndex }) => {
        const { classes } = this.props;
        //console.log(rowData);

        if (rowData.selected) {
            return classes.rowSelected;
        } else return rowIndex % 2 === 0 ? classes.stripped : undefined;
    };
    onColumnSort = ({ key, order, column }) => {
        const { sortState } = this.state;

        this.setState({
            sortState: {
                ...sortState,
                [key]: sortState[key] === SortOrder.DESC ? null : order,
            },
        });

        if (sortState[key] === SortOrder.DESC) {
            delete this.order[column.sortKey || column.dataKey];
            if (Object.keys(this.order).length === 0) this.order = '';
        } else {
            const _order = order === 'asc' ? 1 : -1;
            this.order = {
                ...this.order,
                [column.sortKey || column.dataKey]: _order,
            };
        }

        this.pageIndex = 1;
        this.getConstruction(1, this.search);
        // console.log(sortBy.column.dataKey);
    };
    updateTableData = (data, appointmentUpdate = false) => {
        console.log("appointmentUpdate", appointmentUpdate)
        var rowId = null;
        if (appointmentUpdate) {
            rowId = data._id;
        } else {
            rowId = this.detailedRow ? this.detailedRow.rowData._id : data._id;
        }

        var found = this.state.data.find((val) => val._id === rowId);

        if (found) {
            var keys = Object.keys(data);
            for (let index = 0; index < keys.length; index++) {
                found[keys[index]] = data[keys[index]];
            }
        }

        if (this.detailedRow) {
            this.detailedRow.rowData = found;        // null yapınca dialogu kapatmadan 2. değişikliği yapıp kaydedince hata veriyor.
        }
        else {
            this.detailedRow = { rowData: found }
        }

        this.consData = [];
        this.consData = this.state.data;
        this.setState({ data: this.state.data });
    };
    addItemTableData = (data) => {
        this.consData.unshift(data);
        this.setState({ data: this.consData });
    };
    render() {
        const { data, loading, loadingMore } = this.state;
        const { width, height, EditModal, components, rowRenderer, hideFooter, disableToolbar, rowClassName, rowHeight } = this.props;

        return (
            <div>
                <EditModal
                    formType={this.formType}
                    dialogTitle={this.dialogTitle}
                    updateTableData={this.updateTableData}
                    openDialog={this.state.openDialog}
                    toggleDialog={this.toggleDialog}
                    addItemTableData={this.addItemTableData}
                    selectedRowData={this.detailedRow}
                    inputList={this.props.modalInputList || this.props.inputList.filter((val) => Commons.checkAccessControl(val.role))}
                    rowId={this.detailedRow ? this.detailedRow.rowData._id : null}
                    isAppointment={this.isAppointment}
                    isAddItem={this.state.isAddItem}
                />

                {this.renderDeleteDialog()}
                {this.state.excellData && (
                    <ExcelFile hideElement={true}>
                        <ExcelSheet data={this.state.excellData} name="İnşaatlar">
                            {this.props.inputList
                                .filter(
                                    (val) =>
                                        val.dataKey !== 'ydsClaimsId' &&
                                        val.dataKey !== 'separator' &&
                                        val.dataKey !== 'SteelReinforcement' &&
                                        val.dataKey !== 'UpdateAt' &&
                                        val.dataKey !== 'coordinate' &&
                                        val.dataKey !== 'projectDesigners' &&
                                        val.dataKey !== 'reportList' &&
                                        val.dataKey !== 'personnel'
                                )
                                .map((element, indx) => {
                                    return <ExcelColumn key={indx} style={{ width: '900px' }} label={element.title} value={element.dataKey} />;
                                })}
                        </ExcelSheet>
                    </ExcelFile>
                )}
                {!disableToolbar && this.tableToolBar(width)}
                <BaseTable
                    fixed
                    ref={(ref) => (this.table = ref)}
                    sortState={this.state.sortState}
                    onColumnSort={this.onColumnSort}
                    rowClassName={rowClassName || this._rowClassName}
                    width={width}
                    data={data}
                    height={height - (!hideFooter ? 47 : 0)}
                    columns={this.columnList}
                    disabled={loading}
                    rowRenderer={rowRenderer}
                    loadingMore={loadingMore}
                    onEndReachedThreshold={300}
                    onEndReached={this.handleEndReached}
                    rowHeight={rowHeight || 40}
                    //onScrollbarPresenceChange={}
                    overlayRenderer={this.renderOverlay}
                    emptyRenderer={this.renderEmpty}
                    components={components || { TableCell, TableHeaderCell }}
                    footerHeight={30}
                    footerRenderer={!hideFooter ? this.renderTableFooter(width) : <div style={{ height: '100%' }}></div>}
                    rowEventHandlers={{
                        onClick: (data) => this.onRowClick(data),
                        onDoubleClick: (data) => this.onRowDoubleClick(data),
                    }}
                />
            </div>
        );
    }
    renderTableFooter(width) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: width,
                    height: '100%',
                    backgroundColor: '#f8f8f8',
                    alignItems: 'center',
                }}
            >
                <div style={{ position: 'absolute', right: 10 }}>{'Toplam: ' + this.state.datacount + '/' + this.state.fullcount}</div>
            </div>
        );
    }
    filterTable = () => {
        var filterArray = [];
        try {
            this.filteredFields.forEach((item) => {
                if (this.filterInputRefs[item.dataKey].checkIsEmpty()) {
                    item.value = this.filterInputRefs[item.dataKey].state.value;
                    filterArray[item.dataKey] = this.filterInputRefs[item.dataKey].state.value;
                }
            });

            this.filter = Commons.changeQuery(filterArray);
            this.pageIndex = 1;
            this.getConstruction(1, this.search);
            this.handleClosePopUp();
        } catch (error) {
            console.log(error);
        }
    };
    clearFilter = () => {
        this.filteredFields.forEach((item) => {
            item.value = null;
            this.filterInputRefs[item.dataKey].handleChange(null);
        });
        this.filter = {};
        this.getConstruction(1, this.search);
        this.handleClosePopUp();
    };
    renderDeleteDialog() {
        var foundCol = this.props.inputList.filter((val) => val.selectedTitle === true);
        var selectedDataKEY = foundCol[0].dataKey;

        return (
            <div>
                <Dialog open={this.state.openDeleteDialog} onClose={this.handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">{'Emin misiniz?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {'İşlemi onaylarsanız '}
                            <label style={{ fontWeight: 'bold', color: 'black' }}>
                                {this.selectedItem && this.selectedItem.rowData[selectedDataKEY]}
                            </label>
                            {' adlı kayıt silinecektir.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Reddet
                        </Button>
                        <Button onClick={this.deleteItemDb} color="primary" autoFocus>
                            Onayla
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    tableToolBar(width) {
        const { classes, searchEnabled, excellEnabled, disableAddDelete, disableDelete, disableAdd, customButton } = this.props;
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <div
                style={{
                    width: width,
                    padding: 2,
                    backgroundColor: '#f8f8f8',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }} xs={12}>
                    {!disableAddDelete && (
                        <div>
                            {!disableAdd && (
                                <Button
                                    onClick={() => {
                                        this.addItem('new');
                                    }}
                                    color="inherit"
                                    className={classes.button}
                                >
                                    <AddIcon className={classes.leftIcon} />
                                    <label>Ekle</label>
                                </Button>
                            )}

                            {!disableDelete && (
                                <Button
                                    onClick={() => {
                                        this.deleteItem();
                                    }}
                                    color="inherit"
                                    className={classes.button}
                                >
                                    <DeleteIcon className={classes.leftIcon} />
                                    <label>Sİl</label>
                                </Button>
                            )}
                        </div>
                    )}
                    {excellEnabled && (
                        <Button
                            onClick={() => {
                                this.exportExcell();
                            }}
                            color="inherit"
                            className={classes.button}
                        >
                            <ExportIcon className={classes.leftIcon} />
                            <label>Excell</label>
                        </Button>
                    )}
                    {customButton && customButton()}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={3}>
                    {this.filteredFields.length > 0 && (
                        <IconButton
                            aria-describedby={id}
                            onClick={this.handleClick}
                            style={{
                                height: 40,
                                width: 40,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <FilterIcon style={{ marginTop: -3.5 }} />
                        </IconButton>
                    )}
                    <Popover
                        id={id}
                        open={open}
                        PaperProps={{ style: { zoom: 0.9 } }}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClosePopUp}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div style={{ overflow: 'hidden' }}>
                            <div style={{ padding: 5, paddingLeft: 15, paddingBottom: 0 }}>
                                <Button color="inherit" onClick={this.filterTable} className={classes.button}>
                                    <label>Fİltrele</label>
                                </Button>
                                <Button color="inherit" onClick={this.clearFilter} className={classes.button}>
                                    <label>Temİzle</label>
                                </Button>
                            </div>
                            <Grid
                                container
                                justify="space-around"
                                style={{
                                    width: 400,
                                    paddingLeft: 25,
                                    paddingRight: 15,
                                    paddingBottom: 25,
                                    paddingTop: 0,
                                    overflow: 'auto',
                                }}
                            >
                                {this.filteredFields.map((element, indx) => {
                                    element.editable = true;
                                    element.required = false;

                                    // this.filteredFields.length % 2 !== 0 && this.filteredFields.length - 1 === indx
                                    //     ? (element.type = 1)
                                    //     : (element.type = 2);

                                    return InputTypes(
                                        element,
                                        {
                                            inputRefs: this.filterInputRefs,
                                            updateParent: this.updateRender,
                                            style: {
                                                margin: 0,
                                                padding: 0,
                                                paddingBottom: 5,
                                                width: '100%',
                                            },
                                        },
                                        true
                                    );
                                })}
                            </Grid>
                        </div>
                    </Popover>
                    {searchEnabled && (
                        <Input
                            placeholder="Ara..."
                            style={{
                                marginLeft: 4,
                                marginRight: 15,
                                marginBottom: 4,
                                marginTop: 5,
                            }}
                            onChange={(val) => {
                                this.searchItem(val.target.value);
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
}

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    progress: {
        margin: theme.spacing(2),
    },
    rowSelected: {
        backgroundColor: '#e3e3e3',
        cursor: 'pointer'
    },
    stripped: {
        backgroundColor: '#f6f6f6',
        cursor: 'pointer'
    },
    button: {
        margin: theme.spacing(0.2),
        justifiyContent: 'center',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    menu: {
        width: '100%',
    },
});

export default withStyles(styles)(CustomTable);
