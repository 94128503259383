import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';

export default function SuspanseProgress() {
  const [completed, setCompleted] = React.useState(10);
  const classes = useStyles();
  React.useEffect(() => {
    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldCompleted + diff, 100);
      });
    }

    const timer = setInterval(progress, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <LinearProgress variant="determinate" value={completed} className={classes.suspanseProgress} />;
}

const useStyles = makeStyles((theme) => ({
  suspanseProgress: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 50,
    zIndex: theme.zIndex.drawer + 2,
  },
}));
