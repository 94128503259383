/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';
import { withStyles } from '@mui/styles';
import { Typography, MenuItem, Paper, TextField } from '@mui/material';
import metrics from '../metrics';
import UTF8 from 'utf8';
import Commons from '../functions/Commons';

function NoOptionsMessage(props) {
    return (
        <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
            İnşaat bulunamadı.
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};
class Autocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : '',
            suggestions: [],
            innerValue: '',
            error: false,
            shirink: 'false',
        };
    }

    checkIsEmpty() {
        if (this.state.value) {
            this.setState({ error: false });
            return true;
        } else {
            if (this.props.isRequired) {
                this.setState({ error: true });
                return false;
            }

            return true;
        }
    }
    handleChange = (value) => {
        if (this.props.isMulti) {
            this.setState({ innerValue: value, value: value });
            if (this.props.updateParent) this.props.updateParent(value);
        } else {
            this.setState({ innerValue: value, value: value.value.constructionName });
            if (this.props.updateParent) this.props.updateParent(value.value);
        }
    };

    selectStyles = {
        input: (base) => ({
            ...base,
            color: 'black',
            '& input': {
                font: 'inherit',
            },
        }),
    };
    componentDidMount() {
        if (!this.props.value) {
            this.getItems('');
        }
    }
    getItems = (search) => {
        fetch(`${metrics.URL}/getConstructionInput/?$top=${25}&$search=${UTF8.encode(search)}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: Commons.checkToken(),
            },
        })
            .then((response) => response.json())

            .then((json) => {
                var list2 = [];
                json.Items.map((element) =>
                    list2.push({
                        label: element.yibfno + ' - ' + element.constructionName,
                        value: element,
                    })
                );

                this.setState({ suggestions: list2 });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    render() {
        const { classes, label, isRequired, editable } = this.props;

        return (
            <Select
                {...this.props}
                classes={classes}
                styles={this.selectStyles}
                inputId="react-select-single"
                TextFieldProps={{
                    label: label,
                    InputLabelProps: {
                        htmlFor: 'react-select-single',
                        shrink: this.state.value || this.state.shrink,
                    },
                    error: this.state.error,
                    required: isRequired,
                    disabled: !editable,
                    onChange: (val) => {
                        this.getItems(val.target.value);
                    },
                }}
                onFocus={() => this.setState({ shrink: 'true' })}
                onBlur={() => this.setState({ shrink: false })}
                isDisabled={!editable}
                options={this.state.suggestions}
                components={components}
                // defaultInputValue={this.state.value}
                placeholder={this.state.value}
                //value={this.state.innerValue}
                onChange={this.handleChange}
            />
        );
    }
}

const styles = (theme) => ({
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },

    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});
export default withStyles(styles)(Autocomplete);
