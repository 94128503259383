/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import Select from 'react-select';

import { MenuItem, Chip, Paper, TextField, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { withStyles } from '@mui/styles';
import metrics from '../metrics';

function NoOptionsMessage(props) {
    return (
        <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

async function getListitems(url) {
    try {
        const fetchResult = fetch(`${metrics.URL}/get${url}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        const response = await fetchResult;

        return response.json();
    } catch (e) {
        console.warn(e);
    }
}
const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class MultiAutocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : null,
            suggestions: [],
            itemList: [],
            innerValue: props.value
                ? props.value.map((suggestion) => ({
                    value: suggestion,
                    label: suggestion,
                }))
                : null,
            error: false,
            shrink: false,
            required: this.props.isRequired
        };
    }

    componentDidMount() {
        if (this.props.itemList) {
            var list2 = [];

            this.props.itemList.map((element) =>
                list2.push({
                    label: element.name,
                    value: element.name,
                })
            );

            this.setState({ itemList: list2 });
        } else {
            getListitems(this.props.name).then((data) => {
                this.setState({ itemList: data.Items });
            });
        }
    }
    handleChange = (value) => {
        var val = [];
        if (value)
            value.forEach((element) => {
                val.push(String(element.value));
            });
        this.setState({ innerValue: value, value: val });
    };
    checkIsEmpty() {
        if (this.state.value) {
            this.setState({ error: false });
            return true;
        } else {
            if (this.state.required) {
                this.setState({ error: true });
                return false;
            }

            return true;
        }
    }
    selectStyles = {
        input: (base) => ({
            ...base,
            color: 'black',
            '& input': {
                font: 'inherit',
            },
        }),
    };

    render() {
        const { classes, label, editable } = this.props;

        return (
            <Select
                classes={classes}
                styles={this.selectStyles}
                inputId="react-select-multiple"
                TextFieldProps={{
                    label: label,
                    InputLabelProps: {
                        htmlFor: 'react-select-multiple',
                        shrink: this.state.shrink || this.state.innerValue,
                    },
                    error: this.state.error,
                    required: this.state.required,
                }}
                onFocus={() => this.setState({ shrink: true })}
                onBlur={() => this.setState({ shrink: false })}
                isDisabled={!editable}
                options={this.state.itemList}
                placeholder={''}
                components={components}
                value={this.state.innerValue}
                onChange={this.handleChange}
                isMulti
            />
        );
    }
}

const styles = (theme) => ({
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: theme.palette.grey[300],
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});
export default withStyles(styles)(MultiAutocomplete);
