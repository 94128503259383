/* eslint-disable no-redeclare */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@mui/material';

import Draggable from 'react-draggable';
import InputTypes from '../components/InputTypes';
function PaperComponent(props) {
    return (
        <Draggable handle="#draggableConcrete" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
//1 edit 2 oluştur
var inputRefs = [];
export default function ConcreteCreateEdit(props) {

    const [inputListState, setInputListState] = React.useState([]);

    useEffect(() => {

        const { openDialog, inputList } = props

        if (openDialog) {
            setInputListState(inputList);
        }
        else {
            inputRefs = [];
        }

    }, [props]);

    function updateRender(data) {
        inputListState.find((val) => val.dataKey === 'yibfno').value = data.yibfno;
        setInputListState([...inputListState])
    };

    return (
        <Dialog
            open={props.openDialog}
            onClose={() => props.toggleDialog()}
            scroll="paper"
            maxWidth="sm"
            PaperComponent={PaperComponent}
            aria-labelledby="draggableConcrete"
        >
            <DialogTitle style={{ cursor: 'move', textAlign: 'center', color: '#29487d' }} id="draggableConcrete">
                {props.dialogTitle}
            </DialogTitle>
            <DialogContent style={{ flexGrow: 1 }} dividers>
                <Grid container justify="space-around">
                    {
                        inputListState.map((element, i) => {
                            return InputTypes(element, {
                                inputRefs: inputRefs,
                                updateParent: updateRender,
                            });
                        })}
                </Grid>
            </DialogContent>
            <DialogActions style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse"
            }} >
                <div>
                    <Button onClick={() => props.toggleDialog()} color="primary">
                        Kapat
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );

}
