/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import metrics from '../metrics';
import { TextField, MenuItem, Checkbox } from '@mui/material';
import Commons from '../functions/Commons';

async function getListitems(url) {
    try {
        const fetchResult = fetch(`${metrics.URL}/get${url}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: Commons.getAuthorization(),
            },
        });
        const response = await fetchResult;

        return response.json();
    } catch (e) {
        console.warn(e);
    }
}

export default class CustomMultipleDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : props.value === false ? 'false' : null,
            menuValue: props.value ? props.value : [],
            itemList: [],
            error: false,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.itemList;
        const newVal = newProps.value;

        if (newProps.hasOwnProperty('itemList') && newValue !== this.state.itemList && newValue) {
            this.setState({
                itemList: newValue.filter((val) => Commons.checkAccessControl(val.role)),
            });
        }
        if (newProps.hasOwnProperty('value') && newVal !== this.state.value && newVal) {
            this.setState({
                menuValue: newVal ? newVal : [],
                value: newVal ? String(newVal) : newVal === false ? 'false' : '',
            });
        }
    }
    componentDidMount() {
        if (this.props.itemList) {
            this.setState({
                itemList: this.props.itemList.filter((val) => Commons.checkAccessControl(val.role)),
            });
        } else {
            getListitems(this.props.name).then((data) => {
                var itemList = this.props.additionalItemList ? [...this.props.additionalItemList, ...data.Items] : data.Items;
                this.setState({ itemList: itemList });
            });
        }
    }
    checkIsEmpty() {
        if (this.state.value && this.state.value.length && Array.isArray(this.state.value)) {
            this.setState({ error: false });
            return true;
        } else {
            if (this.props.isRequired) {
                this.setState({ error: true });
                return false;
            }
            return true;
        }
    }
    handleChange = (val) => {
        this.setState({
            value: val ? (val.length > 0 ? val : null) : null,
            menuValue: Array.isArray(val) ? val : [],
        });
    };
    render() {
        const { label, isRequired, editable } = this.props;
        return (
            this.state.itemList.length > 0 && (
                <TextField
                    select
                    error={this.state.error}
                    label={label}
                    fullWidth
                    style={{ width: '100%', appearance: 'none', color: "#000" }}
                    disabled={!editable}
                    required={isRequired}
                    defaultValue={this.props.defaultValue}
                    value={this.state.menuValue}
                    onChange={(val) => this.handleChange(val.target.value)}
                    margin="normal"
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => selected.join(', '),
                    }}
                >
                    {this.state.itemList.map((option) => (
                        <MenuItem key={option.name} value={option.name} style={{ padding: 3, paddingLeft: 3, paddingRight: 16 }} >
                            <Checkbox checked={this.state.menuValue.indexOf(option.name) > -1} />
                            {option.label || option.name}
                        </MenuItem>
                    ))}
                </TextField>
            )
        );
    }
}
