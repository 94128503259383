/* eslint-disable react/prop-types */
import React from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material';
import dialogStore from '../store/DialogStore';
import { observer } from 'mobx-react';

function PaperComponent(props) {
  return (
    <Draggable handle="#customDialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
CustomDialog.propTypes = {
  leftFunc: PropTypes.func,
  open: PropTypes.bool,
  rightFunc: PropTypes.func,
  dialogTitle: PropTypes.string,
  rightButtonText: PropTypes.string,
  leftButtonText: PropTypes.string,
};

function CustomDialog() {
  const { content, leftButtonText, rightButtonText, dialogTitle, open, fullWidth, maxWidth, leftFunc, setOpen, rightFunc } = dialogStore;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperComponent={PaperComponent}
      aria-labelledby="customDialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="customDialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent style={{ flexGrow: 1 }} >
        {content}
      </DialogContent>
      <DialogActions>
        {leftButtonText && <Button onClick={() => leftFunc()} color="primary">
          {leftButtonText}
        </Button>}
        {rightButtonText && <Button onClick={() => rightFunc()} color="primary">
          {rightButtonText}
        </Button>}
      </DialogActions>
    </Dialog>
  );
}
export default observer(CustomDialog);
