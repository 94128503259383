class UserModel {
  constructor() {
    this.User = {
      _id: '',
      email: '',
      password: '',
      name: '',
      surname: '',
      fullName: '',
      position: '',
      fcmToken: '',
      token: '',
      phoneNumber: '',
      role: '',
      status: '',
      gender: '',
      tcNo: '',
      registerNo: '',
      inspectorRegisterNo: '',
      photoUrl: '',
      address: '',
      visible: '',
    };
  }
}
export default UserModel;
