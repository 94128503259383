import React, { useEffect, useState } from 'react'
// import appointmentsInput from '../inputfields/appointmentsInput';
import ekipInputs from '../../../inputfields/ekipInputs.json';
import EkipCreateEdit from '../../../dialogs/EkipCreateEdit';
import CustomTable from '../../../components/CustomTable';
import { Paper, Typography } from '@mui/material';
import { amber, red } from '@mui/material/colors';
import { AutoResizer } from 'react-base-table';
import Text from 'react-texty';
import Commons from '../../../functions/Commons.js';
import metrics from '../../../metrics';
import moment from 'moment';
import DemoUserTableScreen from '../../DemoUserTableScreen'
import WarningIcon from '@mui/icons-material/Warning';
import dialogStore from '../../../store/DialogStore';

const API = {
    getApi: 'getEkip',
    deleteApi: 'deleteEkip',
};

const stringRenderer = ({ className, cellData }) => (
    <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
        {cellData ? String(cellData) : cellData === false ? 'false' : cellData}
    </Text>
);

const statusRenderer = ({ className, cellData }) => (
    <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#fff', color: '#fff' }} className={className}>
        <label style={{ color: Commons.returnConstrStatusColor(cellData) }}>
            {cellData ? String(cellData) : cellData === false ? 'false' : cellData}
        </label>
    </Text>
);

const appointmentStatusRenderer = ({ className, cellData }) => (
    <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#fff', color: '#fff' }} className={className}>
        <label style={{ color: Commons.returnAppointmentStatusColor(cellData), fontWeight: cellData === "Reddedildi" ? 'bold' : 'normal' }}>
            {cellData ? String(cellData) : cellData === false ? 'false' : cellData}
        </label>
    </Text>
);

const dateRenderer = ({ className, cellData }) => (
    <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
        {
            cellData && moment(cellData).format('DD/MM/YYYY')
        }
    </Text>
);

const datetimeRenderer = ({ className, cellData }) => (
    <Text hideArrow placement="bottom-end" tooltipStyle={{ fontSize: 12, backgroundColor: '#808080' }} className={className}>
        {
            cellData && cellData.length > 0 ? moment(cellData[0]).format('DD/MM/YYYY LT - ') + moment(cellData[1]).format('LT') // Onaylanan tarih formatı
                : ""
        }
    </Text>
);

const alertRenderer = ({ className, cellData, rowData }) => {

    return (
        <div></div>
    );
};

const renderers = {
    string: stringRenderer,
    status: statusRenderer,
    date: dateRenderer,
    appointmentStatus: appointmentStatusRenderer,
    alert: alertRenderer,
    datetime: datetimeRenderer
};

const Cell = (cellProps) => {
    const format = cellProps.column.componentType === 3 ? 'date' : (cellProps.column.format || 'string');
    const renderer = renderers[format] || renderers.string;

    return renderer(cellProps);
};

const components = {
    TableCell: Cell,
};

function handleClickOpenAlertDialog(content) {
    dialogStore.dialogTitle = 'Uyarı';
    dialogStore.maxWidth = undefined
    dialogStore.fullWidth = false
    dialogStore.content = content
    dialogStore.leftButtonText = null;

    dialogStore.rightButtonText = 'Kapat';
    dialogStore.rightFunc = () => {
        dialogStore.open = false;
    };
    dialogStore.open = true;
};

export default function Constructions() {
    const [, setWidth] = useState(0);
    var inputList = ekipInputs;

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    function updateWindowDimensions() {
        setWidth(window.innerWidth);
    }

    return (
        <Paper
            style={{
                width: `100%`,
                position: 'relative',
                height: metrics.tableHeight,
            }}
        >
            {!Commons.getValidation().contract && <DemoUserTableScreen />}
            {Commons.getValidation().contract &&
                <>

                    <AutoResizer>
                        {({ width, height }) => (
                            <CustomTable
                                width={width}
                                height={height}
                                API={API}
                                excellEnabled={false}
                                disableAdd={false}
                                disableDelete={!Commons.checkAccessControl(3)}
                                searchEnabled
                                components={components}
                                inputList={inputList}
                                EditModal={EkipCreateEdit}
                            />
                        )}
                    </AutoResizer>
                </>}
        </Paper>
    );
}
