/* eslint-disable import/no-anonymous-default-export */
import routes from './routes';
import history from './history';
import sessionwrapper from './sessionwrapper';
import MD5 from 'md5';
import crypto from 'crypto-js';
import moment from 'moment';
import 'moment/locale/tr'; //For Turkey
import axios from 'axios';
import CompanyInfoStore from '../store/companyInfoStore';
const mapStatus = {
    "Beton Onay Bekleyen": {
        color: 'yellow',
    },
    "Yapı Denetim Onayı Bekleyen": {
        color: 'blue',
    },
    "Onaylandı": {
        color: 'green',
    }
};
const appointmentStatus = {
    "Beton Onay Bekleyen": {
        color: '#cc9406',
    },
    "Reddedildi": {
        color: '#f94144',
    },
    "Onaylandı": {
        color: '#008000',
    },
    "İptal Edildi": {
        color: '#f94144'
    }
};
function findPositionAndType(posit) {
    var position = posit
    var positionType = ""
    if (position === 'Inşaat Mühendisi' || position === 'Insaat Müh' || position === 'Insaat Mühendisi' || position === "İnşaat Mühendisi") {
        positionType = '1'
        position = 'İnşaat Mühendisi'
    } else if (position === 'Elektrik Mühendisi' || position === 'Elektrik  Mühendisi' || position === 'Elektrik Müh') {
        positionType = '2'
        position = 'Elektrik Mühendisi'
    }
    else if (position === 'Mimar') {
        positionType = '3'
    }
    else if (position === 'Makine Mühendisi' || position === 'Makina Mühendisi' || position === 'Makina Müh' || position === 'Makine Müh') {
        positionType = '4'
        position = 'Makine Mühendisi'
    }
    else if (position === 'Tekniker') {
        positionType = '5'
    }
    else if (position === 'Teknisyen') {
        positionType = '6'
    }
    else if (position === 'Teknik Öğretmen') {
        positionType = '6'
    }

    return { position: position, positionType: positionType }
}
function findQualityType(qualityNameP) {
    var qualityType = ""
    var qualityName = qualityNameP;
    if (qualityName === 'Proje ve Uygulama Denetçisi' || qualityName === 'Proje Ve Uygulama Denetçisi' || qualityName === 'Proje Ve Uyg. Denetçisi') {
        qualityType = '1'
    } else if (qualityName === 'Uygulama Denetçisi') {
        qualityType = '2'
    }
    else if (qualityName === 'Kontrol Elemanı' || qualityName === 'Kontrol') {
        qualityType = '3'
        qualityName = 'Kontrol Elemanı'
    }
    else if (qualityName === 'Yardımcı Kontrol Elemanı' || qualityName === "YKE'nin") {
        qualityType = '4'
        qualityName = 'Yardımcı Kontrol Elemanı'
    }
    return { qualityType: qualityType, qualityName: qualityName }
}
function extractPersonnelFromTimeline(obj) {

    const {
        yibf,
        processHistoryType,
        application,
        processDate,
        person
    } = obj

    var position = ""

    if (application && application.title) {
        position = findPositionAndType(String(application.title.name).trim())
    }
    else {
        console.log(yibf.id, person.fullName);
    }

    var personnelData = {
        yibfno: yibf.id,
        tcNo: person.identityNumber,
        name: person.name,
        surname: person.surName,
        fullName: person.fullName,
        photoUrl: "",
        position: position.position,
        positionType: position.positionType,
    }

    if (isDeparture(processHistoryType.id)) {
        personnelData["dateOfDeparture"] = new Date(moment(processDate).format());
    }
    else {
        personnelData["employmentStartDate"] = new Date(moment(processDate).format());
    }

    if (application && application.documentNumber) {
        personnelData["inspectorRegisterNo"] = application.documentNumber
    }
    if (application && application.occupationalRegistrationNumber) {
        personnelData["registerNo"] = application.occupationalRegistrationNumber
    }
    if (application && application.tasks) {

        var qualityByPID = getQualityByPID(processHistoryType.id)
        var qn = String(application.tasks.group.name).trim()
        var qualityN = String(application.tasks.name).trim()
        var _quality = qualityN === "İnşaat" ? qn : qualityN

        if (qualityByPID) {
            _quality = qualityByPID
        }

        var quality = findQualityType(_quality)

        personnelData["qualityName"] = quality.qualityName
        personnelData["qualityType"] = quality.qualityType
    }

    return personnelData
}
function getQualityByPID(pid) {
    switch (pid) {
        case 352:
            return "Kontrol Elemanı"
        case 353:
            return "Kontrol Elemanı"
        case 348:
            return "Yardımcı Kontrol Elemanı"
        case 349:
            return "Yardımcı Kontrol Elemanı"
        default:
            return false;
    }
}
function isDeparture(pid) {
    switch (pid) {
        case 353:
            return true
        case 355:
            return true
        case 349:
            return true
        default:
            return false;
    }
}
export default {
    FormatDate(date) {
        var zero = null;
        if (date.getMonth() < 10) {
            zero = '0' + (date.getMonth() + 1);
        } else {
            zero = date.getMonth() + 1;
        }

        var datetime = [date.getDate(), zero, date.getFullYear()].join('/');

        return datetime;
    },
    checkToken() {
        var user = sessionwrapper.getItem('user');
        if (user) {
            return this.getAuthorization();
        }
        return null;
    },
    getAuthorization() {
        var user = sessionwrapper.getItem('user');

        if (user) {
            return user.token + ' ' + sessionwrapper.getItem('DB');
        }
        return null;
    },
    numberWithCommas(x) {
        return x.toLocaleString('tr-TR');
    },
    getDBName() {
        var user = sessionwrapper.getItem('user');

        if (user) {
            var DB = user.dbName;
            var dbIndex = sessionwrapper.getItem('DB');
            var selectedDB = this.isNoneValue(dbIndex) ? 0 : dbIndex;

            return Array.isArray(DB) ? DB[selectedDB].dbName : DB;
        }

        return null;
    },
    getUserCity() {
        var user = sessionwrapper.getItem('user');

        if (user) {
            return user.customer[0]?.city;
        }

        return null;
    },
    getDBIndex() {
        const dbIndx = sessionwrapper.getItem('DB') ? sessionwrapper.getItem('DB') : 0;
        return dbIndx
    },
    getValidation() {
        var user = sessionwrapper.getItem('user');
        if (user) {
            return user.customer[this.getDBIndex()].validation
        }
        return false;
    },
    getTimeline(yibfList, callback) {

        var token = sessionwrapper.getItem('ydsToken') ? `Bearer ${sessionwrapper.getItem('ydsToken')}` : null
        var personnelList = []
        const ydInfo = CompanyInfoStore.getCurrentCompany();
        var data = JSON.stringify({
            "sort": [{ "selector": "processDate", "desc": true }],
            "searchOperation": "contains",
            "searchValue": null,
            "userData": {},
            "filter": [
                [
                    ["processHistoryType.id", "=", 355], "or",
                    ["processHistoryType.id", "=", 353], "or",
                    ["processHistoryType.id", "=", 349], "or",
                    ["processHistoryType.id", "=", 348], "or",
                    ["processHistoryType.id", "=", 354], "or",
                    ["processHistoryType.id", "=", 352]
                ],
                [yibfList, "and", ["department.name", "=", ydInfo.companyName]]
            ]
        });

        var config = {
            method: 'post',
            url: 'https://businessyds.csb.gov.tr/api/processHistory/findAll',
            headers: {
                'Connection': 'keep-alive',
                'sec-ch-ua': '"Chromium";v="92", " Not A;Brand";v="99", "Google Chrome";v="92"',
                'Accept': 'application/json, text/plain, */*',
                'Authorization': token,
                'sec-ch-ua-mobile': '?0',
                'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.159 Safari/537.36',
                'Content-Type': 'application/json',
                'Origin': 'https://yds.csb.gov.tr',
                'Sec-Fetch-Site': 'same-site',
                'Sec-Fetch-Mode': 'cors',
                'Sec-Fetch-Dest': 'empty',
                'Referer': 'https://yds.csb.gov.tr/',
                'Accept-Language': 'tr,en-US;q=0.9,en;q=0.8,tr-TR;q=0.7'
            },
            data: data
        };
        if (token) {
            axios(config)
                .then(function (response) {

                    const { items } = response.data

                    var distributionDate = new Date(items[0].yibf.distributionDate);


                    items.forEach(element => {

                        if (element.department) {
                            element.department.name === ydInfo.companyName && personnelList.push(extractPersonnelFromTimeline(element))
                        }
                        else {
                            if (new Date(element.processDate) >= new Date(distributionDate)) {
                                personnelList.push(extractPersonnelFromTimeline(element))
                            }
                        }

                    })

                    callback(personnelList)
                })
                .catch(function (error) {
                    callback(false)
                    console.log(error);
                });

        } else {
            callback(false)
        }


    },
    getDBShortNameWithDbName(dbName) {
        var user = sessionwrapper.getItem('user');
        try {
            if (user) {
                var DB = user.dbName;
                var dbInfo = DB.filter((db) => db.dbName === dbName);
                return dbInfo[0].shortName;
            }
            return null;
        } catch (err) {
            return null;
        }
    },
    openInNewTab(href) {
        console.log(href);

        let a = document.createElement('a');
        a.href = href;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        //if (newWindow) newWindow.opener = null
    },
    CapitalizeFirstLetters(str) {
        try {
            return str
                .toLocaleLowerCase('tr-TR')
                .split(' ')
                .map((word) => {
                    return word[0].toLocaleUpperCase('tr-TR') + word.substr(1);
                })
                .join(' ');
        } catch (error) {
            console.log(error);
        }
    },
    replaceParseFloat(text) {
        // eslint-disable-next-line no-useless-escape
        return parseFloat(String(text).replace(/\./g, '').replace(/\,/g, '.'));
    },
    replaceAll(str, term, replacement) {
        return str.replace(new RegExp(this.escapeRegExp(term), 'g'), replacement);
    },
    removeFromString(words, str) {
        return String(words.reduce((result, word) => result.replace(word, ''), str)).trim()
    },
    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },
    convertToTurkishCurrency(number, preFix = "₺") {
        var strNumber = this.replaceAll(String((number).toFixed(2)), '.', ',')

        return preFix + strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    changeQuery(array) {
        var query = {};
        var arr = this.removeFalsy(array);
        Object.keys(arr).forEach((key, i) => {
            // query += key + ':' + array[key];
            if (array[key][0] !== null) {
                query = { ...query, [key]: array[key] };
            }
        });

        return query;
    },
    checkAccessControl(role) {
        const userRole = sessionwrapper.getItem('user');
        if (userRole) {
            if (role) {
                return userRole.role >= role;
            }
            return true;
        }
        return false;
    },
    isNumeric(num) {
        if (!isNaN(num)) {
            return parseInt(num);
        } else {
            return String(num);
        }
    },
    returnDialogTitle() {
        return routes.find((val) => val.path === history.location.pathname).name;
    },
    getColor(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var c = (hash & 0x00ffffff).toString(16).toUpperCase();
        return '#' + '00000'.substring(0, 6 - c.length) + c;
    },
    async getClaimsReport(ydsClaimsId, cookie) {
        try {
            const fetchResult = fetch(`https://uyds.yds.gov.tr/Rapor/HakedisRaporuHtml?hakedis=${ydsClaimsId}`, {
                method: 'GET',
                headers: {
                    'sec-fetch-mode': 'cors',
                    origin: 'https://uyds.yds.gov.tr',
                    'accept-encoding': 'gzip, deflate, br',
                    'accept-language': 'tr-TR,tr;q=0.9,en-US;q=0.8,en;q=0.7',
                    'x-requested-with': 'XMLHttpRequest',
                    cookie: cookie,
                    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    accept: '*/*',
                    referer: 'https://uyds.yds.gov.tr/Giris',
                    authority: 'uyds.yds.gov.tr',
                    'sec-fetch-site': 'same-origin',
                    'user-agent':
                        'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/76.0.3809.100 Safari/537.36',
                },
            });
            const response = await fetchResult;

            return response.text();
        } catch (e) {
            console.warn(e);
        }
    },
    truncate(str, max, sep) {
        // Default to 10 characters
        max = max || 10;

        var len = str.length;
        if (len > max) {
            // Default to elipsis
            sep = sep || '...';

            var seplen = sep.length;

            // If seperator is larger than character limit,
            // well then we don't want to just show the seperator,
            // so just show right hand side of the string.
            if (seplen > max) {
                return str.substr(len - max);
            }

            // Half the difference between max and string length.
            // Multiply negative because small minus big.
            // Must account for length of separator too.
            var n = -0.5 * (max - len - seplen);

            // This gives us the centerline.
            var center = len / 2;

            var front = str.substr(0, center - n);
            var back = str.substr(len - center + n); // without second arg, will automatically go to end of line.

            return front + sep + back;
        }

        return str;
    },
    removeFalsy(obj) {
        let newObj = {};
        Object.keys(obj).forEach((prop) => {
            if (obj[prop] && obj[prop] !== []) {
                newObj[prop] = obj[prop];
            }
        });

        return newObj;
    },
    returnMapStatusColor(status) {
        return mapStatus[status] ? mapStatus[status].color : 'blue';
    },
    returnAppointmentStatusColor(status) {
        return appointmentStatus[status] ? appointmentStatus[status].color : 'blue';
    },
    isNoneValue(value) {
        if (value === null || value === undefined || value === '' || value === 'null') {
            return true;
        }
        if (Array.isArray(value)) {
            if (value.length === 0) {
                return true;
            }
            return false;
        }
        return false;
    },
    addHeadToArray(array = [], element) {
        var removeIndex = array.indexOf(element);
        var deletedArray = array.splice(removeIndex, 1);
        var newArray = deletedArray.splice(0, 0, element);
        console.log(deletedArray);
        console.log(newArray);
        return newArray;
    },
    deEncrypter(candidateKey, encryptedMessage) {
        var key = MD5(candidateKey);
        let decrypt;
        try {
            decrypt = encryptedMessage !== '' ? crypto.AES.decrypt(encryptedMessage, key).toString(crypto.enc.Utf8) : '';
        } catch {
            decrypt = '';
        }
        return decrypt.replace(/@[0-9]*/g, '').trim();
    },
    getDate(dateString, flag = true) {
        var baseDate = moment(dateString).calendar({
            sameDay: flag ? 'LT' : '[BUGÜN]',
            nextDay: flag ? '[Yarın]' : '[YARIN]',
            nextWeek: 'dddd',
            lastDay: flag ? '[Dün]' : '[DÜN]',
            lastWeek: 'dddd',
            sameElse: 'DD/MM/YYYY',
        });
        return baseDate;
    },
    getTime(dateString) {
        var time = moment(dateString).format('LT');
        return time;
    },
    getUserSoketKey(user) {
        return user._id + user.name + user.surname;
    },
    findReportPath(report, town) {
        console.log(report);
        var defpath = report.path ? report.path : report.defaultPath
        if (report.town) {
            console.log(Object.keys(report.town).includes(town) ? report.town[town] : defpath);
            return Object.keys(report.town).includes(town) ? report.town[town] : defpath
        } else {
            return defpath
        }
    },
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    checkTcNum(value) {
        value = value.toString();
        var isEleven = /^[0-9]{11}$/.test(value);
        var totalX = 0;
        for (var i = 0; i < 10; i++) {
            totalX += Number(value.substr(i, 1));
        }
        var isRuleX = totalX % 10 === Number(value.substr(10, 1));

        var totalY1 = 0;
        var totalY2 = 0;
        for (i = 0; i < 10; i += 2) {
            totalY1 += Number(value.substr(i, 1));
        }
        for (i = 1; i < 10; i += 2) {
            totalY2 += Number(value.substr(i, 1));
        }
        var isRuleY = ((totalY1 * 7) - totalY2) % 10 === Number(value.substr(9, 0));
        return isEleven && isRuleX && isRuleY;
    },

};
