import * as React from 'react';
import {
    Typography,
    Zoom,
    Box
} from '@mui/material';
import ydk from '../Api/ydkApi';
import colors from '../functions/colorScheme';
import logo from '../assets/logo01.png';
import history from '../functions/history';

var countDown = 10
const ValidateDemo = () => {

    const [err, setErr] = React.useState(false);
    const [redirectSecond, setRedirectSecond] = React.useState(countDown);

    React.useEffect(() => {

        const guid = window.location.search.replace("?g=", "");

        if (guid === "") {
            setErr(true)
            return
        }

        ydk.startDemoProcess({ guid: guid }).then(res => {
            console.log(res);
            if (res.type === 'error') {
                global.emitter.emit('change', 'error', res.message);
                setErr(true)
                return
            }
            var timer = setInterval(() => {

                --countDown
                setRedirectSecond(countDown)

                if (countDown < 1) {
                    clearInterval(timer);
                    history.replace('/login');
                }

            }, 1000);
        })

    }, [])

    function renderActivation() {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column',
                    padding: 120,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <Zoom in timeout={500}>
                    <Box style={styles.logoDiv} boxShadow={15}>
                        <img alt="" style={{ height: 200 }} src={logo} />
                    </Box>
                </Zoom>
                <div
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography style={styles.text1} gutterBottom>
                        Aktivasyonunuz gerçekleşmiştir
                    </Typography>

                    <Typography style={styles.text2} gutterBottom>
                        Denetmen Lab aktivasyonunuz gerçekleşmiştir. 1 aylık ücretsiz kullanım süreciniz başlamıştır. Tanımlanan süre bitiminde satın alım yapılmamış ise hesabınız deaktive edilecektir.
                    </Typography>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        fontFamily: 'Roboto',
                        color: '#808080',
                        fontSize: 15,
                        textAlign: 'center',
                    }} >
                        <p><span style={{ color: 'black', fontWeight: 'bolder' }}>{redirectSecond}</span> saniye içerisinde giriş ekranınına yönlendirileceksiniz.</p>
                    </div>
                </div>
            </div>
        )
    }

    function renderErr() {
        return (
            <div>Hata</div>
        )
    }

    return (
        <div style={{ backgroundColor: "#f5f5f5" }}>
            {err ? renderErr() : renderActivation()}
            <div style={styles.bottomLine}> </div>
        </div>
    );
}

const styles = {
    mainContainer: {
        // backgroundColor:'#f3f3f3',
        width: '100%',
        position: 'relative',
        height: '100vh',
        zoom: 0.9,
        overflow: 'hidden',
        boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px',
    },
    logoDiv: {
        flexDirection: 'row',
        display: 'flex',
        height: 200,
        width: 200,
        borderRadius: 200,
        overflow: 'hidden',
        alignItems: 'center',
        marginBottom: 30,
    },
    text1: {
        fontFamily: 'Roboto',
        color: '#525252',
        fontSize: 32,
        fontWeight: 300,
        textAlign: 'center',
        //boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px'
    },
    text2: {
        fontFamily: 'Roboto',
        color: '#808080',
        fontSize: 15,
        textAlign: 'center',
        //boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 6px 0px'
    },
    bottomLine: {
        bottom: 0,
        position: 'absolute',
        height: 6,
        width: '100%',
        backgroundColor: colors.denetmen,
        backgroundImage: 'linear-gradient(to right, #6dc5e7, #8360c3)',
    },
};
export default ValidateDemo;