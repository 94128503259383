/* eslint-disable no-unused-vars */
import React from 'react';
import { withStyles } from '@mui/styles';
import townsInputs from '../../inputfields/townsInputs.json';

import CustomTable from '../../components/CustomTable';
import { AutoResizer } from 'react-base-table';
import TownCreateEdit from '../../dialogs/TownCreateEdit';

const API = {
    getApi: 'gettown',
    deleteApi: 'deleteTown',
};

class Towns extends React.Component {
    constructor(props) {
        super(props);

        this.columnList = townsInputs.filter((val) => val.colVisible === true);
    }

    render() {
        return (
            <AutoResizer>
                {({ width, height }) => (
                    <CustomTable
                        width={width}
                        height={height}
                        API={API}
                        disableEdit
                        disableAdd={false}
                        rowHeight={40}
                        inputList={townsInputs}
                        EditModal={TownCreateEdit}
                    />
                )}
            </AutoResizer>
        );
    }
}

const styles = (theme) => ({});
export default withStyles(styles)(Towns);
