/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
    IconButton,
    Drawer,
    CssBaseline,
    AppBar,
    Toolbar,
    Button,
    Hidden,
    ClickAwayListener,
    Typography,
    List,
    Popper,
    Grow,
    Paper,
    Link,
    MenuList,
    Avatar,
    Backdrop,
    MenuItem,
    Tooltip
} from '@mui/material';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItems from './ListItems';
import { deepOrange, deepPurple } from '@mui/material/colors';
import ls from 'local-storage';
import history from '../functions/history';
import sessionwrapper from '../functions/sessionwrapper';
import metrics from '../metrics';
import api from '../functions/api';
import ws from '../functions/ws';
import Commons from '../functions/Commons';
import cookies from 'react-cookies';
import CompanyInfoStore from '../store/companyInfoStore';
import CustomDialog from '../components/CustomDialog';
import moment from 'moment';


function MadeWithLove() {
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body2" color="textSecondary" align="center" style={{ paddingTop: "12px", paddingBottom: "12px", fontSize: "14px" }}>
                {`Copyright © ${new Date().getFullYear()}. `}
                <Link color="primary" target="_new_tab" href="https://shiftsoft.co/" style={{ fontSize: "14px" }}>
                    shiftsoft.co
                </Link>
                {' all rights reserved'}
            </Typography>
        </div>
    );
}
const drawerWidth = 220;

export default function Layout(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };
    const [openMenu, setOpenMenu] = React.useState(false);

    // const userTemp = sessionwrapper.getItem('user')

    React.useEffect(() => {
        if (Commons.getAuthorization()) {
            api.getCompanyInfos().then((result) => {
                CompanyInfoStore.setCurrentCompany(result);
            });
            var user = sessionwrapper.getItem('user');
            //console.log(user);
            //chatStore.setUserForLogin(user, Commons.getDBName());
            if (!ws.ws().connected) {
                ws.createWS(Commons.getAuthorization());

                ws.ws().on('newAppointmentLAB', (data) => {
                    global.emitter.emit('addNewDataToTable', '/Appointments', data);
                    global.emitter.emit('change', 'success', 'Yeni Randevu Geldi!');
                });

                ws.ws().on('deleteAppointmentLAB', id => {
                    var data = { "_id": id, "appointmentStatus": "İptal Edildi" };

                    global.emitter.emit('updateTableData', '/Appointments', data);
                    global.emitter.emit('change', 'warning', 'Bir randevu iptal edildi!');
                })

                ws.ws().on('updateAppointmentLAB', obj => {
                    var data = { "_id": obj._id, "appointmentStatus": "Yapı Denetim Onayı Bekleyen", "concreteAppointmentDate": obj.concreteAppointmentDate, "isRejected": obj.isRejected };

                    global.emitter.emit('updateTableData', '/Appointments', data);
                    global.emitter.emit('change', 'success', `${obj.constructionName} adlı inşaatın randevusu beton firması tarafından onaylandı.`);
                })
                ws.ws().on('confirmAppointmentLAB', obj => {

                    var data = { "_id": obj._id, "appointmentStatus": "Onaylandı", "confirmedAppointmentDate": obj.confirmedAppointmentDate };

                    global.emitter.emit('updateTableData', '/Appointments', data);
                    global.emitter.emit('change', 'success', `${obj.constructionName} adlı inşaatın randevusu yapı denetim firması tarafından onaylandı.`);
                })
                ws.ws().on('rejectAppointmentLAB', obj => {

                    var data = { "_id": obj._id, "appointmentStatus": "Reddedildi", "isRejected": true };

                    global.emitter.emit('updateTableData', '/Appointments', data);
                    global.emitter.emit('change', 'warning', `${obj.constructionName} adlı inşaatın randevusu yapı denetim firması tarafından reddedildi.`);
                })
                // chatStore.setUser(user, Commons.getDBName(), user.dbName);
            } else {
                if (ws.ws().disconnected) {
                    ws.ws().open();
                    // chatStore.setUser(user, Commons.getDBName(), user.dbName);
                }
            }
        }

        // console.log("çağrıldı", ws.connected);

        // returned function will be called on component unmount
        return () => { };
    }, []);

    function handleToggle() {
        setOpenMenu((prevOpen) => !prevOpen);
    }

    function handleClose(event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenMenu(false);
    }

    function getTwoChar() {
        var user = sessionwrapper.getItem('user');
        if (user) {
            var words = user.fullName.split(' ');
            return words[0].charAt(0) + words.slice(-1)[0].charAt(0);
        }
    }

    function checkToken() {
        var user = sessionwrapper.getItem('user');

        if (!user) {
            return false;
        }
        fetch(`${metrics.URL}/checkToken`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: user.token,
            }),
        })
            .then((response) => response.json())
            .then((json) => { })
            .catch((err) => {
                history.replace('/login');
            });

        // return false
    }

    function getName() {
        checkToken();
        var user = sessionwrapper.getItem('user');
        if (user) return user.fullName;
    }
    function getDBNames() {
        checkToken();
        var user = sessionwrapper.getItem('user');
        if (user) return user.dbName;
    }
    function Logout() {
        ls.remove('token');
        sessionwrapper.clearAll();
        ws.ws().disconnect();
        // chatStore.resetAllData();
        cookies.remove('ydsCookie');
        history.replace(`/login`);
    }
    function routeCompany() {
        history.push('/CompanyInfo');
        // handleClose()
    }
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const anchorRef = React.useRef(null);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Backdrop className={classes.backdrop} open={open} onClick={handleDrawerClose}></Backdrop>

            <CustomDialog />
            <AppBar position="absolute" color="inherit" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar variant="dense" className={classes.toolbar}>
                    <Button
                        disableFocusRipple
                        disableRipple
                        onClick={() => {
                            history.replace(`/`);
                        }}
                    >
                        <Typography variant="subtitle1" color="black" fontSize={16}>
                            Denetmen.lab
                        </Typography>
                    </Button>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.title}></div>
                    {Commons.getValidation() && Commons.getValidation().alert &&
                        <Tooltip disableFocusListener disableTouchListener title={`${Commons.getValidation().type} süreniz ${moment(Commons.getValidation().date).format('Do MMMM YYYY')} tarihinde sona eriyor. Lütfen bizimle iletişime geçiniz.`}>
                            <RunningWithErrorsIcon color='error' style={{ marginRight: '10px', width: '24px' }} />
                        </Tooltip>
                    }

                    <Button
                        ref={anchorRef}
                        style={{ padding: 0 }}
                        aria-controls="menu-list-grow"
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <Avatar className={classes.avatar}>{getTwoChar()}</Avatar>
                        <div
                            style={{
                                color: '#808080',
                                textTransform: 'capitalize',
                                fontSize: 18,
                            }}
                        >
                            <Hidden xsDown>{getName()}</Hidden>
                        </div>
                    </Button>

                    <Popper open={openMenu} anchorEl={anchorRef.current} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper id="menu-list-grow">
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList>
                                            {Array.isArray(getDBNames()) &&
                                                getDBNames().map((val, indx) => {
                                                    const dbIndx = Commons.getDBIndex()
                                                    return (
                                                        <MenuItem
                                                            key={String(indx)}
                                                            selected={indx === dbIndx}
                                                            style={{ width: 150 }}
                                                            onClick={() => {
                                                                sessionwrapper.setItem('DB', indx);
                                                                window.location.reload();
                                                            }}
                                                        >
                                                            {' '}
                                                            {val.shortName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            {
                                                Commons.checkAccessControl(2) && <MenuItem onClick={routeCompany}>Şirket Bilgileri</MenuItem>
                                                /* <MenuItem onClick={handleClose}>My account</MenuItem>*/
                                            }
                                            <MenuItem style={{ width: 150 }} onClick={Logout}>
                                                Çıkış
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Toolbar>
            </AppBar>
            <Drawer
                keepmounted="true"
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}></Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <List>
                    <ListItems />
                </List>

                {/*Commons.checkAccessControl(sessionwrapper.getItem('user'), 2) && <List>{secondaryListItems}</List>*/}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <div maxwidth="lg" className={classes.container}>
                    {props.children}
                </div>
                <MadeWithLove />
            </main>
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zoom: 1,
        height: 50,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: '0px 2px 16px -5px rgba(0,0,0,0.5)',
    },
    appBarShift: {
        marginLeft: 0,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 15,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'absolute',
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.primary.main,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    },
    drawerPaperClose: {
        position: 'absolute',

        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(0),
        },
    },
    appBarSpacer: { minHeight: 55 },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
        },
    },
    container: {
        padding: theme.spacing(2.2),
        paddingBottom: 0,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    avatar: {
        margin: 8,
    },
    margin: {
        margin: theme.spacing(1),
    },
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    button: {
        margin: theme.spacing(0.8),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
        color: '#fff',
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
}));
