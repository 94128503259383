// /* eslint-disable no-prototype-builtins */
// /* eslint-disable react/prop-types */
import moment from 'moment';
import trLocale from 'date-fns/locale/tr';
import TextField from '@mui/material/TextField';
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Box from '@mui/material/Box';
import React from 'react';
import 'moment/locale/tr';
moment.locale('tr'); // it is required to select default locale manually

export default class CustomDateRange extends React.Component {
    // The first commit of Material-UI
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : [null, null],
            error: false,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const newValue = newProps.value;
        if (newProps.hasOwnProperty('value') && newValue !== this.state.value && newValue) {
            this.setState({
                value: newValue,
            });
        }
    }
    handleChange(dates) {
        this.setState({
            value: dates ? dates : [null, null],
        });
    }
    checkIsEmpty() {
        if (this.state.value) {
            this.setState({ error: false });
            return true;
        } else {
            if (this.props.isRequired) {
                this.setState({ error: true });
                return false;
            }

            return true;
        }
    }

    render() {
        const { label, isRequired } = this.props;
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                <MobileDateRangePicker
                    cancelText="İptal"
                    clearText="Temİzle"
                    required={isRequired}
                    okText="Tamam"
                    clearable
                    toolbarTitle={String(label).toLocaleUpperCase()}
                    inputFormat="dd/MM/yyyy"
                    startText="Başlangıç"
                    endText="Bitiş"
                    value={this.state.value}
                    calendars={2}
                    //showToolbar={false}
                    onChange={(newValue) => {
                        this.handleChange(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextField {...startProps} label={`${label} Baş.`} margin="none" />
                            <Box sx={{ mx: 2 }}>
                                {' '}
                                <SwapHorizIcon />{' '}
                            </Box>
                            <TextField {...endProps} label={`${label} Bitiş`} margin="none" />
                        </React.Fragment>
                    )}
                />
            </LocalizationProvider>
        );
    }
}
