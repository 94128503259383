const wrapper = {
    storageAdaptor: sessionStorage,

    // Thanks Angus! - http://goo.gl/GtvsU
    toType: function (obj) {
        return {}.toString
            .call(obj)
            .match(/\s([a-z|A-Z]+)/)[1]
            .toLowerCase();
    },

    getItem: function (key) {
        var item = this.storageAdaptor.getItem(key);

        try {
            item = JSON.parse(item);
        } catch (e) {
            console.log("getitem");
        }

        return item;
    },

    setItem: function (key, value) {
        var type = this.toType(value);

        if (/object|array/.test(type)) {
            value = JSON.stringify(value);
        }

        this.storageAdaptor.setItem(key, value);
    },

    removeItem: function (key) {
        this.storageAdaptor.removeItem(key);
    },

    clearAll: function () {
        this.storageAdaptor.clear();
    },
};
export default wrapper;